<template>
    <div class="color_input">
        <b-field>
            <b-input type="text"
                     :name="name"
                     :value="colorValue"
                     @input="updateColor($event)"
                     maxlength="7"/>
            <p class="control">
                <b-button class="button is-primary" @click="showColorPicker = !showColorPicker">
                    <span class="icon"><i class="fas fa-eye-dropper"/></span>
                    &nbsp;
                    <span v-if="!showColorPicker">Elige un color...</span>
                    <span v-if="showColorPicker">Cerrar picker</span>
                </b-button>
            </p>
        </b-field>
        <color-picker v-if="showColorPicker" :value="colorValue || '#ffffff'" @input="updateColor($event.hex || 0)"/>
    </div>
</template>

<script>
    import {Sketch} from 'vue-color';

    export default {
        name: "ColorInput",
        components: {
            'color-picker': Sketch
        },
        props: {
            name: {required: false, default: null},
            value: {required: false, default: '#ffffff'}
        },
        data() {
            return {
                showColorPicker: false,
                colorValue: null
            }
        },
        mounted() {
            this.updateColor(this.value)
        },
        methods: {
            updateColor(value) {
                if (value) {
                    this.colorValue = value
                }
                this.$emit('input', value)
            }
        }
    }
</script>

<style scoped>

</style>
