<template>
    <div class="slug-input">
        <b-field :type="type">
            <b-input ref="slugInput"
                     type="text"
                     :name="name"
                     :value="this.slug"
                     :id="name || id"
                     @input="setSlug($event)"
                     expanded :required="required"
            />
            <p class="control" v-if="!hideSync">
                <b-button @click="reBindInput"><i :class="syncIcon"></i></b-button>
            </p>
        </b-field>
        <b-field :type="type" :message="message" v-if="type"></b-field>
        <b-message class="is-link slug-preview">
            {{ prefix }}<span v-html="slug"></span>
        </b-message>
    </div>
</template>

<script>
    export default {
        name: "SlugInput",
        props: {
            input: String,
            value: String,
            prefix: String,
            name: {required: true, default: ""},
            id: String,
            required: Boolean,
            allowSlash: { type: Boolean, default: false},
            hideSync: { type: Boolean, default: false},
            syncedOnStart: { type: Boolean, default: true},
            type: String,
            message: Array,
            forceRootSlash: { type: Boolean, default: false},
        },
        data() {
            return {
                dirty: false,
                slug: this.value,
                tempSlug: ""
            }
        },
        mounted() {
            this.updateValue(this.value)
            this.tempSlug = this.slug
            this.dirty = !this.syncedOnStart
        },
        computed: {
            syncIcon() {
                console.log(this.dirty);
                return this.dirty ? 'fas fa-lock-open' : 'fas fa-lock';
            }
        },
        methods: {
            slugify(str) {
                if (typeof str !== "string") return;
                const regex = this.allowSlash ? /[^a-z0-9\/]/g : /[^a-z0-9]/g
                let slug = str
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(regex, "|")
                    .replace(/[\|]+/g, "-")

                if (this.forceRootSlash && slug.charAt(0) !== '/') {
                    slug = `/${slug}`;
                }
                return slug.replace(/\/+/g,'/')
            },
            updateValue(value) {
                this.slug = this.slugify(value)
                this.$emit('input', this.slug)
            },
            setSlug(value) {
                this.dirty = true;
                this.updateValue(value)
            },
            syncSlug(value) {
                if (this.dirty) {
                    this.tempSlug = value;
                    return;
                }
                this.updateValue(value)
            },
            reBindInput() {
                this.dirty = !this.dirty;
                this.syncSlug(this.tempSlug);
            }
        }
    }
</script>

<style scoped>

</style>
