var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-message",
    { attrs: { type: _vm.type, "has-icon": "" } },
    [_vm._v("\n    " + _vm._s(_vm.text) + "\n    "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }