var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "listing-header" },
        [
          _c("search-input", {
            attrs: { value: _vm.searchStr },
            on: {
              input: function($event) {
                return _vm.search($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          paginated: "",
          "backend-pagination": "",
          total: _vm.total,
          "per-page": _vm.perPage,
          "backend-sorting": "",
          "default-sort-direction": _vm.defaultSortDirection,
          "default-sort": [_vm.sortField, _vm.sortDir]
        },
        on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "title", label: "Titulo", sortable: "" } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(props.row.title) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "publishing_date",
                      label: "Fecha de publicación",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.publishing_date,
                            "ddd DD/MM/YYYY HH:mm"
                          )
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "expiration_date",
                      label: "Fecha de expiración",
                      sortable: ""
                    }
                  },
                  [
                    props.row.expires
                      ? _c("span", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm._f("moment")(
                                  props.row.expiration_date,
                                  "ddd DD/MM/YYYY HH:mm"
                                )
                              ) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !props.row.expires
                      ? _c("span", [_vm._v("\n\t\t\t\t\t\t-\n\t\t\t\t\t")])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "on_air", label: "On Air", sortable: "" } },
                  [
                    props.row.on_air
                      ? _c("b-tag", { attrs: { type: "is-danger" } }, [
                          _vm._v("Ocupado")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "url", label: "Url", sortable: "" } },
                  [
                    _c(
                      "a",
                      { attrs: { href: props.row.fullUrl, target: "_blank" } },
                      [_vm._v(_vm._s(props.row.url))]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "insertion_date",
                      label: "Fecha de inserción",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.insertion_date,
                            _vm.dateFormat
                          )
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "update_date",
                      label: "Fecha de actualización",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.update_date,
                            _vm.dateFormat
                          )
                        ) +
                        "\n\t\t\t\t"
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Acciones" } },
                  [
                    !_vm.selector
                      ? _c("crud-actions", {
                          attrs: {
                            id: props.row.id,
                            resource: "specials",
                            title: props.row.title
                          },
                          on: { deleted: _vm.fetchData }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selector && !_vm.multiple
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "",
                              pack: "far",
                              icon: "check-circle"
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(props.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                        Seleccionar\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selector && _vm.multiple
                      ? _c("b-checkbox", {
                          attrs: { "native-value": props.row.id },
                          on: { input: _vm.cbSelected },
                          model: {
                            value: _vm.itemsSelected,
                            callback: function($$v) {
                              _vm.itemsSelected = $$v
                            },
                            expression: "itemsSelected"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }