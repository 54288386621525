<template>
    <div class="form media-form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>

            <b-field label="Thumbnail"
                     type="is-danger"
                     :type="errors.type('thumbnail')"
                     :message="errors.get('thumbnail')">
                <image-upload-box
                    ref="thumbnail"
                    :default-url="form.thumbnail_url"
                    v-model="thumbData"/>
            </b-field>

            <b-message>
                Tamano maximo de archivo 3Mb. Resolucion maxima recomendada 1280x720 con una relacion de aspecto 16:9
            </b-message>

            <b-field label="Tipo de Asset"
                     :type="errors.type('type')"
                     :message="errors.get('type')">
                <b-select v-model="form.type" name="type" required>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                </b-select>
            </b-field>

            <b-field label="* Titulo"
                     :type="errors.type('title')"
                     :message="errors.get('title')">
                <b-input
                    name="title"
                    type="text"
                    v-model="form.title"
                    @change="bindCleanTitle(form.title)"
                    @input="bindCleanTitle(form.title)"
                    expanded required
                />
            </b-field>

            <b-field label="Url"
                     :type="errors.type('clean_title')"
                     :message="errors.get('clean_title')">
                <slug-input
                    ref="slug"
                    v-model="form.clean_title"
                    prefix="https://apuntmedia.es/../"
                    name="clean_title"
                    :synced-on-start="syncTitleWithSlug"
                    expanded :required="true"/>
            </b-field>

            <b-field
                label="Descripcion"
                :type="errors.type('description')"
                :message="errors.get('description')">
                <summernote class="form-control"
                            name="editor"
                            v-model="form.description"
                ></summernote>
            </b-field>

            <b-field label="* Idioma"
                     :type="errors.type('language')"
                     :message="errors.get('language')">
                <b-select v-model="form.language" name="language" required>
                    <option value="ES">Espanol</option>
                    <option value="CA">Valenciano</option>
                    <option value="EN">Ingles</option>
                </b-select>
            </b-field>

            <b-field label="Calificacion moral"
                     :type="errors.type('rating')"
                     :message="errors.get('rating')">
                <b-select v-model="form.rating" name="rating" required>
                    <option value="0">Publico General</option>
                    <option value="7">+7</option>
                    <option value="10">+10</option>
                    <option value="12">+12</option>
                    <option value="13">+13</option>
                    <option value="16">+16</option>
                    <option value="18">+18</option>
                </b-select>
            </b-field>

            <b-field label="Fecha de publicacion"
                     :type="errors.type('publishing_date')"
                     :message="errors.get('publishing_date')">
                <calendar-input
                    v-model="form.publishing_date">
                </calendar-input>
            </b-field>

            <b-field label="Fecha de finalizacion"
                     :type="errors.type('expiration_date')"
                     :message="errors.get('expiration_date')">
                <calendar-input
                    v-model="form.expiration_date"></calendar-input>
            </b-field>

            <b-field label="Keywords"
                     :type="errors.type('keywords')"
                     :message="errors.get('keywords')">
                <b-taginput
                    v-model="form.keywords"
                    ellipsis
                    icon="label"
                    placeholder="Agregar keyword">
                </b-taginput>
            </b-field>

            <b-message>
                Lista de palabras clave separadas por comas
            </b-message>

            <b-field label="Labels"
                     :type="errors.type('labels')"
                     :message="errors.get('labels')">
                <multi-select
                    :multiple="true"
                    v-model="form.labels"
                    data-url="/labels/listing"
                />
            </b-field>

            <b-field label="Player ID"
                     :type="errors.type('player_bc')"
                     :message="errors.get('player_bc')">
                <async-select
                    :multiple="true"
                    v-model="form.player_bc"
                    data-url="/bcapi/players-list"
                />
            </b-field>

            <b-field label="Ad Keys"
                     :type="errors.type('ad_keys')"
                     :message="errors.get('ad_keys')">
                <b-input
                    name="ad_keys"
                    type="text"
                    v-model="form.ad_keys"
                    expanded
                />
            </b-field>

            <div class="level">
                <div class="level-item has-text-centered">
                    <b-field label="Video 360"
                             :type="errors.type('v360')"
                             :message="errors.get('v360')"
                    >
                        <b-switch name="v360"
                                  v-model="form.v360"
                                  true-value="1" false-value="0"/>
                    </b-field>
                </div>
                <div class="level-item has-text-centered">
                    <b-field label="Requiere registro">
                        <b-switch name="require_user_sign_in"
                                  v-model="form.require_user_sign_in"
                                  true-value="1" false-value="0"/>
                    </b-field>
                </div>
            </div>
            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button tag="a" href="/media">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import moment from 'moment';
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import Summernote from '../../lib/Summernote';
    import ImageUploadBox from "../fields/ImageUploadBox";
    import SlugInput from "../fields/SlugInput";
    import MultiSelect from "../fields/MultiSelect";
    import AsyncSelect from "../fields/AsyncSelect";
    import CalendarInput from "../fields/CalendarInput";

    export default {
        name: "MediaForm",
        components: {CalendarInput, AsyncSelect, MultiSelect, ImageUploadBox, SlugInput, Summernote},
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                thumbData: null,
                id: null,
                syncTitleWithSlug: true,
                dateFormat: process.env.MIX_DBT_DATE_FORMAT,
                form: {
                    thumbnail: null,
                    thumbnail_url: null,
                    type: 'video',
                    title: null,
                    clean_title: null,
                    description: null,
                    rating: 0,
                    language: 'CA',
                    publishing_date: new Date(),
                    expiration_date: moment().add(1, 'years').toDate(),
                    keywords: null,
                    v360: 0,
                    require_user_sign_in: 0,
                    labels: [],
                    player_bc: 'default',
                    ad_keys: null
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values && this.values.id) {
                this.id = this.values.id;
                this.form = {...this.form, ...this.values};
                this.form.publishing_date = new Date(this.form.publishing_date)
                this.form.expiration_date = new Date(this.form.expiration_date)
                this.form.player_bc = this.form.player_bc || 'default';
            }

            this.syncTitleWithSlug = false;
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();

                    if (this.thumbData) {
                        formData.append('thumbnail', this.thumbData);
                    }

                    formData.append('type', this.form.type || '');
                    formData.append('title', this.form.title || '');
                    formData.append('clean_title', this.form.clean_title || '');
                    formData.append('description', this.form.description || '');
                    formData.append('rating', this.form.rating);
                    formData.append('language', this.form.language || '');
                    formData.append('publishing_date', moment(this.form.publishing_date).format());
                    formData.append('expiration_date', moment(this.form.expiration_date).format());
                    formData.append('keywords', this.form.keywords || '');
                    formData.append('v360', this.form.v360 || 0);
                    formData.append('require_user_sign_in', this.form.require_user_sign_in || 0);
                    formData.append('labels', this.form.labels || []);
                    formData.append('player_bc', this.form.player_bc || '');
                    formData.append('ad_keys', this.form.ad_keys || '');

                    console.log(this.form);

                    let url = `/media/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    this.errors.reset();

                    this.$buefy.toast.open({
                        message: 'El asset ha sido guardado',
                        type: 'is-success'
                    });

                    const values = result.data.values
                    // if (!this.form.id && result.data.id) {
                    //     setTimeout(() => {
                    //         let toks = window.location.href.split('/');
                    //         toks.push(result.data.id);
                    //         window.location.href = toks.join('/');
                    //     }, 2000);
                    if (values && values.thumbnail_url) {
                        this.$refs.thumbnail.reset(values.thumbnail_url);
                    }
                    this.submitting = false;
                } catch (error) {
                    console.log(error)
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Hay problemas con el formulario',
                        type: 'is-danger'
                    });
                }
            },
            bindCleanTitle(value) {
                this.$refs.slug.syncSlug(value)
            }
        }
    }
</script>
