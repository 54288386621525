var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    [
      _c(
        "b-select",
        {
          attrs: { value: _vm.selectedValue, loading: _vm.loading },
          nativeOn: {
            change: function($event) {
              return _vm.updateValue($event.target.value)
            }
          }
        },
        _vm._l(_vm.options, function(option) {
          return _c(
            "option",
            { key: option.id, domProps: { value: option.id } },
            [
              _vm._v(
                "\n            (" +
                  _vm._s(option.id) +
                  ") " +
                  _vm._s(option.name) +
                  "\n        "
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.canClear
        ? _c(
            "p",
            { staticClass: "control" },
            [
              _c(
                "b-button",
                { staticClass: "button", on: { click: _vm.clearValue } },
                [_c("b-icon", { attrs: { icon: "eraser" } })],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }