var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form news-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns" },
          [
            _c(
              "b-field",
              {
                staticClass: "column",
                attrs: {
                  label: "Mostrar Foto/Video",
                  type: _vm.errors.type("news_show_picture_only"),
                  message: _vm.errors.get("news_show_picture_only")
                }
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: { name: "news_show_picture_only" },
                    model: {
                      value: _vm.form.news_show_picture_only,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "news_show_picture_only", $$v)
                      },
                      expression: "form.news_show_picture_only"
                    }
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [_vm._v("Video")]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("Foto")])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "column",
                attrs: {
                  label: "Id del video",
                  type: _vm.errors.type("news_video_asset"),
                  message: _vm.errors.get("news_video_asset")
                }
              },
              [
                _c("video-id-selector", {
                  attrs: {
                    id: "news_video_asset",
                    name: "news_video_asset",
                    "data-url": "/news/listing/noticia,video"
                  },
                  model: {
                    value: _vm.form.news_video_asset,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "news_video_asset", $$v)
                    },
                    expression: "form.news_video_asset"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            Selecciona una opcion como preferente para mostrar en la cabecera si existe tanto video como thumbnail.\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Thumbnail",
              type: "is-danger",
              type: _vm.errors.type("thumbnail"),
              message: _vm.errors.get("thumbnail")
            }
          },
          [
            _c("image-upload-box", {
              ref: "thumbnail",
              attrs: { "default-url": _vm.form.thumbnail_url },
              model: {
                value: _vm.thumbData,
                callback: function($$v) {
                  _vm.thumbData = $$v
                },
                expression: "thumbData"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            Escoja thumbnail o video asociado, pero no ambos. En caso de que exista un thumbnail y un video puede\n            seleccionar abajo que elemento aparecera en la cabecera. Puede insertar mas imagenes en el cuerpo de la\n            noticia.\n            Tamano maximo de archivo 1Mb. Resolucion maxima recomandada 1280x720 con una relacion de aspecto 16:9\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "* Titulo",
              type: _vm.errors.type("title"),
              message: _vm.errors.get("title")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "title", type: "text", expanded: "" },
              on: {
                change: function($event) {
                  return _vm.bindCleanTitle(_vm.form.title)
                },
                input: function($event) {
                  return _vm.bindCleanTitle(_vm.form.title)
                }
              },
              model: {
                value: _vm.form.title,
                callback: function($$v) {
                  _vm.$set(_vm.form, "title", $$v)
                },
                expression: "form.title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Url" } },
          [
            _c("slug-input", {
              ref: "slug",
              attrs: {
                prefix: "https://apuntmedia.es/../",
                type: _vm.errors.type("clean_title"),
                message: _vm.errors.get("clean_title"),
                "synced-on-start": _vm.syncTitleWithSlug,
                name: "clean_title"
              },
              model: {
                value: _vm.form.clean_title,
                callback: function($$v) {
                  _vm.$set(_vm.form, "clean_title", $$v)
                },
                expression: "form.clean_title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Descripcion",
              type: _vm.errors.type("description"),
              message: _vm.errors.get("description")
            }
          },
          [
            _c("summernote", {
              staticClass: "form-control",
              attrs: { name: "editor" },
              model: {
                value: _vm.form.description,
                callback: function($$v) {
                  _vm.$set(_vm.form, "description", $$v)
                },
                expression: "form.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Descripcion corta",
              type: _vm.errors.type("short_description"),
              message: _vm.errors.get("short_description")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "textarea", name: "short_description" },
              model: {
                value: _vm.form.short_description,
                callback: function($$v) {
                  _vm.$set(_vm.form, "short_description", $$v)
                },
                expression: "form.short_description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Alt",
              type: _vm.errors.type("alt_thumbnail"),
              message: _vm.errors.get("alt_thumbnail")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text", name: "alt_thumbnail" },
              model: {
                value: _vm.form.alt_thumbnail,
                callback: function($$v) {
                  _vm.$set(_vm.form, "alt_thumbnail", $$v)
                },
                expression: "form.alt_thumbnail"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Pie de Foto",
              type: _vm.errors.type("thumb_description"),
              message: _vm.errors.get("thumb_description")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "textarea", name: "thumb_description" },
              model: {
                value: _vm.form.thumb_description,
                callback: function($$v) {
                  _vm.$set(_vm.form, "thumb_description", $$v)
                },
                expression: "form.thumb_description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "Indica los autores (Si hubiera). El campo URL es para el perfill de twitter del mismo, y debe de\n            ser una URL bien formada o sino, no te permitira guardar. un ejemplo es:\n            http://www.twitter.com/apunt_media\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "authors" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.form.authors.slice(0, 3), function(author, index) {
              return _c(
                "div",
                { staticClass: "columns" },
                [
                  _c("div", { staticClass: "field column is-1" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(index + 1))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "column",
                      attrs: { message: _vm.errors.get("author.name") }
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.authors[index].name,
                          callback: function($$v) {
                            _vm.$set(_vm.form.authors[index], "name", $$v)
                          },
                          expression: "form.authors[index].name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "column",
                      attrs: { message: _vm.errors.get("author.url") }
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.form.authors[index].url,
                          callback: function($$v) {
                            _vm.$set(_vm.form.authors[index], "url", $$v)
                          },
                          expression: "form.authors[index].url"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            El idioma es obligatorio y necesario para podes filtrar los video por idioma.\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "* Idioma",
              type: _vm.errors.type("language"),
              message: _vm.errors.get("language")
            }
          },
          [
            _c(
              "b-select",
              {
                attrs: { name: "language" },
                model: {
                  value: _vm.form.language,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "language", $$v)
                  },
                  expression: "form.language"
                }
              },
              [
                _c("option", { attrs: { value: "ES" } }, [_vm._v("Espanol")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "CA" } }, [
                  _vm._v("Valenciano")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "EN" } }, [_vm._v("Ingles")])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _c("strong", [_vm._v("Importante: ")]),
          _vm._v(
            "Los assets no se publicaran en la platforma si su fecha de activacion esta\n            en el futuro o si la fecha de desactivation ha expirado\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Fecha de publicacion",
              type: _vm.errors.type("publishing_date"),
              message: _vm.errors.get("publishing_date")
            }
          },
          [
            _c("calendar-input", {
              model: {
                value: _vm.form.publishing_date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "publishing_date", $$v)
                },
                expression: "form.publishing_date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Fecha de finalizacion",
              type: _vm.errors.type("expiration_date"),
              message: _vm.errors.get("expiration_date")
            }
          },
          [
            _c("calendar-input", {
              model: {
                value: _vm.form.expiration_date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "expiration_date", $$v)
                },
                expression: "form.expiration_date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            Lista de palabras clave separadas por comas\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Keywords",
              type: _vm.errors.type("keywords"),
              message: _vm.errors.get("keywords")
            }
          },
          [
            _c("b-taginput", {
              attrs: {
                ellipsis: "",
                icon: "label",
                placeholder: "Agregar keyword"
              },
              model: {
                value: _vm.form.keywords,
                callback: function($$v) {
                  _vm.$set(_vm.form, "keywords", $$v)
                },
                expression: "form.keywords"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Comarcas",
              type: _vm.errors.type("counties"),
              message: _vm.errors.get("counties")
            }
          },
          [
            _c("multi-select", {
              attrs: { multiple: true, "data-url": "/regions/listing" },
              model: {
                value: _vm.form.counties,
                callback: function($$v) {
                  _vm.$set(_vm.form, "counties", $$v)
                },
                expression: "form.counties"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Labels",
              type: _vm.errors.type("labels"),
              message: _vm.errors.get("labels")
            }
          },
          [
            _c("multi-select", {
              attrs: { multiple: true, "data-url": "/labels/listing" },
              model: {
                value: _vm.form.labels,
                callback: function($$v) {
                  _vm.$set(_vm.form, "labels", $$v)
                },
                expression: "form.labels"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Player ID",
              type: _vm.errors.type("player_bc"),
              message: _vm.errors.get("player_bc")
            }
          },
          [
            _c("async-select", {
              attrs: { multiple: true, "data-url": "/bcapi/players-list" },
              model: {
                value: _vm.form.player_bc,
                callback: function($$v) {
                  _vm.$set(_vm.form, "player_bc", $$v)
                },
                expression: "form.player_bc"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/news" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "is-success",
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "field column is-1" }, [
        _c("div", { staticClass: "label" }, [_vm._v("#")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field column" }, [
        _c("div", { staticClass: "label" }, [_vm._v("Autor")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field column" }, [
        _c("div", { staticClass: "label" }, [_vm._v("URL")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }