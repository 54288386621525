<template>
    <div class="form covers-form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>

            <div class="box tv-container is-clearfix">
                <div class="tv-item has-text-centered is-pulled-left" v-for="(e, index) in form.elements">
                    <b-field>
                        <item-selector
                            v-model="e.value"
                            :default-value="e.data"
                            :can-remove="true"
                            data-url="/news/listing/video,audio"/>
                    </b-field>
                </div>
            </div>

            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button
                                tag="a" href="/news">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import MultiSelect from "../fields/MultiSelect"
    import ItemSelector from "../fields/ItemSelector"
    import CoverValue from "../../lib/CoverValue";

    export default {
        name: "CoverTvForm",
        components: {ItemSelector, MultiSelect},
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                id: null,
                form: {
                    elements: [],
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values && this.values.id) {
                this.id = this.values.id;

                this.form.elements = this.values.elements.map((e) => {
                    return new CoverValue(e)
                })
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();
                    let elements = this.form.elements.map((e) => {
                        return e.out()
                    })
                    formData.append('elements', JSON.stringify(elements));
                    console.log(JSON.stringify(elements));

                    let url = `/covers/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    if (!(result.data && result.data.id)) {
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'La portada ha sido actualizada',
                        type: 'is-success'
                    });

                    this.errors.reset();
                    this.submitting = false;
                } catch (error) {
                    console.log(error)
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Something went wrong',
                        type: 'is-danger'
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    .form-wrapper .tv-container {
        .tv-item {
            max-width: 280px;
            margin: 10px;

            .thumb-box.item-selector {
                min-height: 340px;

                article div {
                    overflow: hidden;
                    height: 140px;
                    margin-bottom: 10px;
                    padding: 0 1em;

                    img {
                        margin-bottom: 0;
                    }

                    footer {
                        min-height: 3em;
                    }
                }
            }
        }
    }
</style>
