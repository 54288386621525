import {Local} from "./Request";
import TwitterScript from "../components/scripts/TwitterScript"
import FBScript from "../components/scripts/FBScript";

export default {
    components: {
        TwitterScript, FBScript
    },
    template: `
        <div>
            <textarea @input="updateValue($event)" :name="name"></textarea>
            <TwitterScript></TwitterScript>
            <FBScript></FBScript>
        </div>`,
    props: {
        value: {type: String},
        name: {
            type: String,
            required: true,
        },
        height: {
            type: String,
            default: '300'
        }
    },
    data() {
        return {
            config: {
                height: this.height,
                dialogsInBody: true,
                popover: {
                    image: [
                        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                        ['float', ['floatLeft', 'floatRight', 'floatNone']],
                        ['remove', ['removeMedia']]
                    ],
                    link: [
                        ['link', ['linkDialogShow', 'unlink']]
                    ],
                    table: [
                        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
                    ],
                    air: [
                        ['color', ['color']],
                        ['font', ['bold', 'underline', 'clear']],
                        ['para', ['ul', 'paragraph']],
                        ['table', ['table']],
                        ['insert', ['link', 'picture']]
                    ]
                },
                lang: 'es-ES', // Change to your chosen language
                imageAttributes: {
                    icon: '<i class="note-icon-pencil"/>',
                    removeEmpty: false, // true = remove attributes | false = leave empty if present
                    disableUpload: false // true = don't display Upload Options | Display Upload Options
                },
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    // ["RRSS", ["tweet", "facebook"]],
                    ['view', ['fullscreen', 'codeview', 'help']],
                ],
                buttons: {
                    tweet: this.tweetButton,
                    facebook: this.facebookButton
                }
            },
            $editor: null
        }
    },
    mounted() {
        this.$editor = $(this.$el)
        let vm = this;
        this.config.callbacks = {
            onInit: () => {
                this.$editor.summernote("code", this.value);
            },
            onChange: () => {
                vm.updateValue(this.$editor.summernote('code'));
            },
            onBlur: () => {
                vm.updateValue(this.$editor.summernote('code'));
            },
            onBlurCodeview: () => {
                vm.updateValue(this.$editor.summernote('code'));
            },
            onImageUpload: (files) => {
                this.uploadImage(files[0])
            }
        }

        this.$editor.summernote(this.config)
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
        async uploadImage(file) {
            try {
                let formData = new FormData()
                formData.append('image', file);

                let result = await Local.post('/media/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                if (result.data.url) {
                    this.$editor.summernote('insertImage', result.data.url);
                } else {
                    alert('La imagen no se ha cargado. Intentelo nuevamente.')
                }
            } catch (error) {
                alert('La imagen no pude ser publicada. Intentelo nuevamente.')
            }
        },
        tweetButton(context) {
            return $.summernote.ui.button({
                contents: '<i class="fab fa-twitter" aria-hidden="true" />',
                // tooltip: "Insertar Tweet",
                click: () => {
                    const ui = $.summernote.ui

                    var $container = this.config.dialogsInBody ? $(document.body) : $editor;

                    var body = `<div class="form-group row-fluid modal-body">
                        <label for="tweetId">Insertar id del tweet</label>
                        <input type="text" class="form-control" id="tweetId" aria-describedby="id del tweet"><br>
                        <small id="help_tweet" class="form-text text-muted">Ejemplo: 1249998934422020097</small>
                        </div>`
                    var footer = `<button href="#" class="btn btn-primary save-btn">Insertar</button>`

                    var $dialog = ui.dialog({
                        title: 'Insertar Tweet',
                        fade: true,
                        body: body,
                        footer: footer
                    }).render().appendTo($container);

                    ui.onDialogShown($dialog, () => {
                        this.$editor.summernote('saveRange')

                        context.triggerEvent('dialog.shown');
                        const $input = $dialog.find('#tweetId')
                        const $btn = $dialog.find('.save-btn')
                        $btn.on('click', (ev) => {
                            ev.preventDefault();
                            console.log($input.val())

                            var tweetId = $input.val()
                            var div = '<div class="tweet" id=' + tweetId + ' ></div>'
                            this.$editor.summernote('restoreRange')
                            this.$editor.summernote('focus')
                            this.$editor.summernote('pasteHTML', div)

                            var tweets = $(".tweet:empty");
                            $(tweets).each(function (t, tweet) {
                                var id = $(this).attr('id')
                                window.twttr.widgets.createTweet(
                                    id, tweet, {
                                        conversation: 'none', // or all
                                        cards: 'visible', // or visible
                                        theme: 'light', // or dark
                                        align: 'center', // or left or right
                                        lang: 'ca'
                                    })
                            })

                            ui.hideDialog($dialog);
                            context.invoke('editor.restoreRange');
                        })
                    });

                    ui.showDialog($dialog);
                }
            }).render()
        },
        facebookButton(context) {
            return $.summernote.ui.button({
                contents: '<i class="fab fa-facebook-square" aria-hidden="true" />',
                // tooltip: "Insertar post de Facebook",
                click: () => {
                    const ui = $.summernote.ui

                    var $container = this.config.dialogsInBody ? $(document.body) : $editor;

                    var body = `<div class="form-group row-fluid modal-body">
                        <label for="facebookUrl">Insertar la URL de facebook</label><br>
                        <input type="text" class="form-control" id="facebookUrl" size="70" aria-describedby="URL del post"><br>
                                    <small id="emailHelp" class="form-text text-muted">Ejemplo: https://www.facebook.com/apuntmedia/posts/2538847166356521</small>
                        </div>`
                    var footer = `<button href="#" class="btn btn-primary save-btn">Insertar</button>`

                    var $dialog = ui.dialog({
                        title: 'Insertar post de Facebook',
                        fade: true,
                        body: body,
                        footer: footer
                    }).render().appendTo($container);

                    ui.onDialogShown($dialog, () => {
                        this.$editor.summernote('saveRange')

                        context.triggerEvent('dialog.shown');
                        const $input = $dialog.find('#facebookUrl')
                        const $btn = $dialog.find('.save-btn')
                        $btn.on('click', (ev) => {
                            ev.preventDefault();

                            ui.hideDialog($dialog);
                            context.invoke('editor.restoreRange');

                            var fbUrl = $input.val()
                            var div = '<div class="fb-post" data-href=' + fbUrl + ' data-width="500" data-show-text="true"></div>'
                            this.$editor.summernote('restoreRange')
                            this.$editor.summernote('focus')
                            this.$editor.summernote('pasteHTML', div)

                            window.fbAsyncInit()
                        })
                    });

                    ui.showDialog($dialog);
                }
            }).render()
        }
    }
}
