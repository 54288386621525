<template>
    <div class="form labels-form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>

            <div class="columns">
                <b-field label="Thumbnail"
                         type="is-danger"
                         class="column"
                         :type="errors.type('thumbnail')"
                         :message="errors.get('thumbnail')">
                    <image-upload-box ref="thumbnail" :default-url="form.thumbnail" v-model="thumbData"/>
                </b-field>
                <b-field class="column"
                         label="Destacados"
                         :type="errors.type('video_asset')"
                         :message="errors.get('video_asset')">
                    <video-id-selector
                        id="video_asset"
                        name="video_asset"
                        data-url="/news/listing/noticias,video,audio"
                        v-model="form.video_asset"
                    />
                </b-field>
            </div>

            <b-field label="Nombre"
                     :type="errors.type('name')"
                     :message="errors.get('name')">
                <b-input type="text" name="name"
                         v-model="form.name"
                         maxlength="64"/>
            </b-field>

            <b-field label="Padre Label"
                     :type="errors.type('parent_id')"
                     :message="errors.get('parent_id')">
                <multi-select
                    :multiple="false"
                    :flat="false"
                    @input="fetchSlug($event)"
                    v-model="form.parent_id"
                    data-url="/labels/listing"
                />
            </b-field>

            <b-field label="Url">
                <slug-input
                    ref="slug"
                    v-model="form.full_name"
                    :prefix="labelPrefix"
                    :allow-slash="true"
                    :force-root-slash="true"
                    :hide-sync="true"
                    :type="errors.type('full_name')"
                    :message="errors.get('full_name')"
                    name="full_name"></slug-input>
            </b-field>

            <b-field label="Visible en" class="field-options">
                <nav class="level">
                    <div class="level-item has-text-centered">
                        <b-field label="Smart TV">
                            <b-switch name="smarttv_visible"
                                      v-model="form.smarttv_visible"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>
                    <div class="level-item has-text-centered">
                        <b-field label="Movíl">
                            <b-switch name="mobile_visible"
                                      v-model="form.mobile_visible"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>
                    <div class="level-item has-text-centered">
                        <b-field label="Web">
                            <b-switch name="web_visible"
                                      v-model="form.web_visible"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>
                </nav>
            </b-field>

            <b-field label="Flags" class="field-options">
                <nav class="level">
                    <div class="level-item has-text-centered">
                        <b-field label="Es un link">
                            <b-switch name="is_link"
                                      v-model="form.is_link"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>
                    <div class="level-item has-text-centered">
                        <b-field label="Es audio">
                            <b-switch name="is_audio"
                                      v-model="form.is_audio"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>
                    <div class="level-item has-text-centered">
                        <b-field label="Es noticia">
                            <b-switch name="is_news"
                                      v-model="form.is_news"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>
                    <div class="level-item has-text-centered">
                        <b-field label="Asistencia de público">
                            <b-switch name="real_public_assistance"
                                      v-model="form.real_public_assistance"
                                      true-value="1" false-value="0"/>
                        </b-field>
                    </div>

                </nav>
            </b-field>

            <b-field label="iTunes Podcast url"
                     :type="errors.type('itunes_url')"
                     :message="errors.get('itunes_url')">
                <b-input type="text" name="itunes_url"
                         v-model="form.itunes_url"
                         maxlength="100"/>
            </b-field>

            <b-field label="Ivoox Podcast url"
                     :type="errors.type('ivoox_url')"
                     :message="errors.get('ivoox_url')">
                <b-input type="text" name="ivoox_url"
                         v-model="form.ivoox_url"
                         maxlength="100"/>
            </b-field>

            <b-field label="Info url"
                     :type="errors.type('info_url')"
                     :message="errors.get('info_url')">
                <b-input type="text" name="info_url"
                         v-model="form.info_url"
                         maxlength="100"/>
            </b-field>

            <b-field label="Color de fondo"
                     :type="errors.type('bc_color')"
                     :message="errors.get('bg_color')">
                <color-input name="bg_color" v-model="form.bg_color"/>
            </b-field>

            <b-field label="Color de texto"
                     :type="errors.type('txt_color')"
                     :message="errors.get('txt_color')">
                <color-input name="txt_color" v-model="form.txt_color"/>
            </b-field>

            <b-field label="Programes A-Z"
                     :type="errors.type('is_item')"
                     :message="errors.get('is_item')">
                <b-select name="is_item" v-model="form.is_item" expanded>
                    <option value="0">No aparece en Programes A-Z</option>
                    <option value="1">Aparece como item</option>
                    <option value="2">Muestra su contenido</option>
                </b-select>
            </b-field>

            <b-field label="Descripcion"
                     :type="errors.type('description')"
                     :message="errors.get('description')">
                <b-input type="textarea" name="description"
                         v-model="form.description"/>
            </b-field>

            <b-field label="Layout">
                <p v-if="values.protected">{{ values.layout }}</p>
                <b-select v-model="form.layout" v-if="!values.protected">
                    <option value="parent_with_principal">Grid con descripción</option>
                    <option value="grid">Grid</option>
                    <option value="entertainment">Entertainment</option>
                    <option value="news_home">News Home</option>
                    <option value="parent_automatic">Parent Auto</option>
                </b-select>
            </b-field>

            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button tag="a" href="/labels">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>
    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from "../../lib/Request";
    import ImageUploadBox from "../fields/ImageUploadBox";
    import SlugInput from "../fields/SlugInput";
    import VideoIdSelector from "../fields/VideoIdSelector";
    import MultiSelect from "../fields/MultiSelect";
    import AsyncSelect from "../fields/AsyncSelect";

    export default {
        name: "LabelsForm",
        components: {ImageUploadBox, SlugInput, VideoIdSelector, MultiSelect, AsyncSelect},
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                thumbData: null,
                id: null,
                parentSlug: '',
                form: {
                    thumbnail: null,
                    name: null,
                    full_name: null,
                    parent_id: null,
                    smarttv_visible: 0,
                    mobile_visible: 0,
                    web_visible: 0,
                    is_audio: 0,
                    is_news: 0,
                    is_link: 0,
                    video_asset: null,
                    itunes_url: null,
                    ivoox_url: null,
                    info_url: null,
                    bg_color: '#ffffff',
                    txt_color: '#ffffff',
                    description: null,
                    is_item: 0,
                    layout: 'parent_with_principal'
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            console.log(this.form, this.values);

            if (this.values && this.values.id) {
                this.id = this.values.id;
                console.log(this.values)
                this.form = {...this.form, ...this.values}
                this.form.full_name = this.form.full_name.split('/').reverse()[0];
            }
        },
        computed: {
            labelPrefix() {
                return `https://apuntmedia.es${this.parentSlug}`.replace(/\/$/, '')
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();

                    if (this.thumbData) {
                        formData.append('thumb', this.thumbData);
                    }

                    formData.append('name', this.form.name || '');
                    formData.append('full_name', `${this.parentSlug}${this.form.full_name}`.replace(/\/+/g, '/') || '');
                    formData.append('parent_id', this.form.parent_id || '');
                    formData.append('smarttv_visible', this.form.smarttv_visible || 0);
                    formData.append('mobile_visible', this.form.mobile_visible || 0);
                    formData.append('web_visible', this.form.web_visible || 0);
                    formData.append('is_audio', this.form.is_audio || 0);
                    formData.append('is_news', this.form.is_news || 0);
                    formData.append('is_link', this.form.is_link || 0);
                    formData.append('video_asset', this.form.video_asset || '');
                    formData.append('itunes_url', this.form.itunes_url || '');
                    formData.append('ivoox_url', this.form.ivoox_url || '');
                    formData.append('info_url', this.form.info_url || '');
                    formData.append('bg_color', this.form.bg_color || '');
                    formData.append('txt_color', this.form.txt_color || '');
                    formData.append('description', this.form.description || '');
                    formData.append('is_item', this.form.is_item || 0);
                    formData.append('layout', this.form.layout);

                    let url = `/labels/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    this.$buefy.toast.open({
                        message: 'El label ha sido guardado',
                        type: 'is-success'
                    });

                    this.errors.reset();

                    const values = result.data.values
                    if (!this.form.id && result.data.id) {
                        setTimeout(() => {
                            let toks = window.location.href.split('/');
                            toks.push(result.data.id);
                            window.location.href = toks.join('/');
                        }, 2000);
                    } else if (values && values.thumbnail) {
                        this.$refs.thumbnail.reset(values.thumbnail);
                    }
                    this.submitting = false;
                } catch (error) {
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Hay errores en el formulario',
                        type: 'is-danger'
                    });
                }
            },
            fetchSlug() {
                if (!(this.form.parent_id)) {
                    return
                }

                Local.get(`/labels/listing/slug/${this.form.parent_id}`)
                    .then((result) => {
                        this.parentSlug = result.data.value
                    });
            },

        }
    }
</script>

<style lang="scss">

</style>
