var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slug-input" },
    [
      _c(
        "b-field",
        { attrs: { type: _vm.type } },
        [
          _c("b-input", {
            ref: "slugInput",
            attrs: {
              type: "text",
              name: _vm.name,
              value: this.slug,
              id: _vm.name || _vm.id,
              expanded: "",
              required: _vm.required
            },
            on: {
              input: function($event) {
                return _vm.setSlug($event)
              }
            }
          }),
          _vm._v(" "),
          !_vm.hideSync
            ? _c(
                "p",
                { staticClass: "control" },
                [
                  _c("b-button", { on: { click: _vm.reBindInput } }, [
                    _c("i", { class: _vm.syncIcon })
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.type
        ? _c("b-field", { attrs: { type: _vm.type, message: _vm.message } })
        : _vm._e(),
      _vm._v(" "),
      _c("b-message", { staticClass: "is-link slug-preview" }, [
        _vm._v("\n        " + _vm._s(_vm.prefix)),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.slug) } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }