var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "listing-header" },
        [
          _c("search-input", {
            attrs: { value: _vm.searchStr },
            on: {
              input: function($event) {
                return _vm.search($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          paginated: "",
          "backend-pagination": "",
          total: _vm.total,
          "per-page": _vm.perPage,
          "backend-sorting": "",
          "default-sort-direction": _vm.defaultSortDirection,
          "default-sort": [_vm.sortField, _vm.sortDir]
        },
        on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "id", label: "ID", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.id) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "first_name",
                      label: "First Name",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.first_name) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "last_name",
                      label: "Last Name",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.last_name) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "email", label: "Email", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.email) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Actions" } },
                  [
                    !_vm.selector
                      ? _c("crud-actions", {
                          attrs: {
                            id: props.row.id,
                            resource: "users",
                            title: props.row.title
                          },
                          on: { deleted: _vm.fetchData }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selector
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              type: "",
                              pack: "far",
                              icon: "check-circle"
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(props.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Seleccionar\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }