var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form covers-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box hero-box" },
          [
            _c(
              "b-field",
              [
                _c("item-selector", {
                  attrs: {
                    "default-value": _vm.form.elements[0].data,
                    "data-url": "/news/listing/all"
                  },
                  model: {
                    value: _vm.form.elements[0].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[0], "value", $$v)
                    },
                    expression: "form.elements[0].value"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "b-field",
              {
                attrs: { label: "Título", message: _vm.errors.get("element.1") }
              },
              [
                _c("b-input", {
                  attrs: { name: "title", type: "text", expanded: "" },
                  model: {
                    value: _vm.form.elements[1].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[1], "value", $$v)
                    },
                    expression: "form.elements[1].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Més", message: _vm.errors.get("labels") } },
              [
                _c("multi-select", {
                  attrs: {
                    multiple: false,
                    flat: false,
                    "data-url": "/labels/listing"
                  },
                  model: {
                    value: _vm.form.elements[2].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[2], "value", $$v)
                    },
                    expression: "form.elements[2].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[3].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[3].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[3], "value", $$v)
                          },
                          expression: "form.elements[3].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[4].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[4].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[4], "value", $$v)
                          },
                          expression: "form.elements[4].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "b-field",
              {
                attrs: { label: "Título", message: _vm.errors.get("element.1") }
              },
              [
                _c("b-input", {
                  attrs: { name: "title", type: "text", expanded: "" },
                  model: {
                    value: _vm.form.elements[5].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[5], "value", $$v)
                    },
                    expression: "form.elements[5].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Més", message: _vm.errors.get("labels") } },
              [
                _c("multi-select", {
                  attrs: {
                    multiple: false,
                    flat: false,
                    "data-url": "/labels/listing"
                  },
                  model: {
                    value: _vm.form.elements[6].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[6], "value", $$v)
                    },
                    expression: "form.elements[6].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[7].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[7].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[7], "value", $$v)
                          },
                          expression: "form.elements[7].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[8].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[8].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[8], "value", $$v)
                          },
                          expression: "form.elements[8].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[9].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[9].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[9], "value", $$v)
                          },
                          expression: "form.elements[9].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "b-field",
              {
                attrs: { label: "Título", message: _vm.errors.get("element.1") }
              },
              [
                _c("b-input", {
                  attrs: { name: "title", type: "text", expanded: "" },
                  model: {
                    value: _vm.form.elements[10].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[10], "value", $$v)
                    },
                    expression: "form.elements[10].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Més", message: _vm.errors.get("labels") } },
              [
                _c("multi-select", {
                  attrs: {
                    multiple: false,
                    flat: false,
                    "data-url": "/labels/listing"
                  },
                  model: {
                    value: _vm.form.elements[11].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[11], "value", $$v)
                    },
                    expression: "form.elements[11].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[12].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[12].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[12], "value", $$v)
                          },
                          expression: "form.elements[12].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[13].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[13].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[13], "value", $$v)
                          },
                          expression: "form.elements[13].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[14].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[14].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[14], "value", $$v)
                          },
                          expression: "form.elements[14].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[15].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[15].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[15], "value", $$v)
                          },
                          expression: "form.elements[15].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "b-field",
              {
                attrs: { label: "Título", message: _vm.errors.get("element.1") }
              },
              [
                _c("b-input", {
                  attrs: { name: "title", type: "text", expanded: "" },
                  model: {
                    value: _vm.form.elements[16].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[16], "value", $$v)
                    },
                    expression: "form.elements[16].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Més", message: _vm.errors.get("labels") } },
              [
                _c("multi-select", {
                  attrs: {
                    multiple: false,
                    flat: false,
                    "data-url": "/labels/listing"
                  },
                  model: {
                    value: _vm.form.elements[17].value,
                    callback: function($$v) {
                      _vm.$set(_vm.form.elements[17], "value", $$v)
                    },
                    expression: "form.elements[17].value"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[18].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[18].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[18], "value", $$v)
                          },
                          expression: "form.elements[18].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[19].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[19].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[19], "value", $$v)
                          },
                          expression: "form.elements[19].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[20].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[20].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[20], "value", $$v)
                          },
                          expression: "form.elements[20].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column has-text-centered" },
                [
                  _c(
                    "b-field",
                    [
                      _c("item-selector", {
                        attrs: {
                          "default-value": _vm.form.elements[21].data,
                          "data-url": "/news/listing/all"
                        },
                        model: {
                          value: _vm.form.elements[21].value,
                          callback: function($$v) {
                            _vm.$set(_vm.form.elements[21], "value", $$v)
                          },
                          expression: "form.elements[21].value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/news" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }