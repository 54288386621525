<template>
    <b-datetimepicker
        :value="value"
        @input="updateValue($event)"
        placeholder=""
        icon="calendar-today"
        position="is-top-right"
        :datetime-formatter="formatDate"
        :datetime-parser="parseDate"
        editable
        :datepicker="{
            'first-day-of-week': 1,
            'years-range': [-5,20]
        }">
        <template slot="left">
            <b-button class="button is-primary"
                      @click="updateValue(new Date())">
                <b-icon icon="clock"></b-icon>
                <span>Hoy</span>
            </b-button>
        </template>

    </b-datetimepicker>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "CalendarInput",
        props: {
            value: {type: Date}
        },
        data() {
            return {
                selectedDate: null
            }
        },
        mounted() {
            this.updateValue(this.value)
        },
        methods: {
            updateValue(value) {
                console.log(value)
                this.selectedDate = value
                this.$emit('input', value)
            },
            formatDate(date) {
                return moment(date).format('DD/MM/YYYY HH:mm')
            },
            parseDate(date) {
                const out = moment(date, 'DD/MM/YYYY HH:mm');
                if (out.isValid()) {
                    return out.toDate()
                }
                return this.selectedDate
            }
        }
    }
</script>

<style scoped>

</style>
