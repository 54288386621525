<template>
    <b-message :type="type" has-icon>
        {{ text }}
        <slot></slot>
    </b-message>
</template>

<script>
    export default {
        name: "FlashBox",
        props: {
            message: { required: true }
        },
        data() {
            return {
                type: 'is-danger',
                text: null
            }
        },
        created() {
            this.type = this.message.type || 'is-danger';
            this.text = this.message.text || 'Blank message';
        }
    }
</script>

<style lang="scss">

</style>
