var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "listing-header" },
        [
          _c("search-input", {
            attrs: { value: _vm.searchStr },
            on: {
              input: function($event) {
                return _vm.search($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          paginated: "",
          "backend-pagination": "",
          total: _vm.total,
          "per-page": _vm.perPage,
          "backend-sorting": "",
          "default-sort-direction": _vm.defaultSortDirection,
          "default-sort": [_vm.sortField, _vm.sortDir]
        },
        on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "modified",
                      label: "Fecha de subida",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.modified) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "type", label: "Tipo", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.type) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "name", label: "Archivo", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.name) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("b-table-column", { attrs: { label: "Actions" } }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.downloadRow(
                              props.row.type,
                              props.row.name
                            )
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { icon: "download" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.deleteRow(props.row.name)
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { icon: "delete" } })],
                      1
                    )
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }