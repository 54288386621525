<template>
    <div class="container">
        <b-message class="is-dark">Completar y activar</b-message>
        <div class="box-wrapper">
            <h2>Home</h2>
            <b-field label="URL">
                <b-input v-model="data[1].url"/>
            </b-field>
            <b-field label="Texto">
                <b-input v-model="data[1].title"/>
            </b-field>
            <footer class="level">
                <div class="level-left">
                    <b-checkbox v-model="data[1].checkbox">
                        Activo
                    </b-checkbox>
                </div>
                <div class="level-right">
                    <b-button type="is-success" @click="saveData(1)">Guardar</b-button>
                </div>
            </footer>
        </div>
        <div class="box-wrapper">
            <h2>Notícias</h2>
            <b-field label="URL">
                <b-input v-model="data[2].url"/>
            </b-field>
            <b-field label="Texto">
                <b-input v-model="data[2].title"/>
            </b-field>
            <footer class="level">
                <div class="level-left">
                    <b-checkbox v-model="data[2].checkbox">
                        Activo
                    </b-checkbox>
                </div>
                <div class="level-right">
                    <b-button type="is-success" @click="saveData(2)">Guardar</b-button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    const originalData = () => {
        return {
            '1': {

            },
            '2': {

            }
        }
    };
    export default {
        mounted() {
        },
        data: () => {
            return {
                data: originalData()
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                try {
                    const result = await Local.get('/breaking-news/listing');
                    if (result && result.data && Array.isArray(result.data) &&result.data.length){
                        this.data = originalData();
                        result.data.forEach(item =>{
                            this.data[item.type] = item;
                            this.data[item.type].checkbox = !!item.active;
                        });
                        console.log(this.data);
                    }
                } catch (error){
                    console.error('error getting data');
                }
            },
            async saveData(type) {
                try {
                    const id = this.data[type].id;
                    this.data[type].active = this.data[type].checkbox ? 1 : 0;
                    const result = await Local.put(`/breaking-news/${id}`,this.data[type]);
                    if (!(result && result.data && result.data.success)){
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'El aviso ha sido guardado',
                        type: 'is-success'
                    });
                    this.fetchData();
                } catch (error) {
                    console.error(error);
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Hay problemas con el formulario',
                        type: 'is-danger'
                    });
                }
            }
        }
    }
</script>
