var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "listing-header" },
        [
          _c("search-input", {
            attrs: { value: _vm.searchStr },
            on: {
              input: function($event) {
                return _vm.search($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          paginated: "",
          "backend-pagination": "",
          total: _vm.total,
          "per-page": _vm.perPage,
          "backend-sorting": "",
          "default-sort-direction": _vm.defaultSortDirection,
          "default-sort": [_vm.sortField, _vm.sortDir]
        },
        on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "id", label: "ID", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.id) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "title", label: "Titulo", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.title) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "duration",
                      label: "Duración",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.duration) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "skip_time",
                      label: "Posición",
                      sortable: ""
                    }
                  },
                  [
                    _c("b-tag", { attrs: { type: "is-info" } }, [
                      _vm._v(_vm._s(props.row.position))
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.skip_time) +
                        "\n            "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "publishing_date",
                      label: "Se publica el",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("moment")(props.row.start_at, _vm.dateFormat)
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "expire_at",
                      label: "Hasta el",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("moment")(props.row.end_at, _vm.dateFormat)
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "updated_at",
                      label: "Actualizado el",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("moment")(props.row.updated_at, _vm.dateFormat)
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Actions" } },
                  [
                    _c("crud-actions", {
                      attrs: {
                        id: props.row.id,
                        resource: "ads",
                        title: props.row.title
                      },
                      on: { deleted: _vm.fetchData }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }