<template>
    <div class="error-message" v-if="text">
        <b-message type="is-danger" has-icon="true" size="is-small">
            {{ text }}
        </b-message>
    </div>
</template>

<script>
    export default {
        name: "ErrorMessage",
        props: {
            text: {required: false, default: null},
        },
        data() {
            return {}
        }
    }
</script>

<style scoped>

</style>
