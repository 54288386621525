<template>
    <div>
        <a :href="`/${resource}/edit/${id}`">
            <b-icon icon="pencil"/>
        </a>
        <span @click="deleteResource(id)">
            <b-icon icon="delete"/>
        </span>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    export default {
        props: {
            resource: {required: true, default: null},
            id: {required: true, default: null},
            title: {required: false, default: null}
        },
        mounted() {
        },
        methods: {
            async deleteResource(id){
                try {
                    const confirmation = confirm('Desea eliminar este elemento?');
                    if (!confirmation){
                        return;
                    }
                    // todo add modal for confirming deletion
                    const result = await Local.delete(`/${this.resource}/${id}`);
                    if (!(result && result.data && result.data.success)){
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'Registro eliminado',
                        type: 'is-success'
                    })
                    this.$emit('deleted');
                } catch(error){
                    console.error(error);
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'El elemento no pudo ser eliminado',
                        type: 'is-danger'
                    });
                }
            }
        }
    }
</script>
