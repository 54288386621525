<template>
    <div class="search-input">
        <b-field>
            <b-input
                rounded
                :value="searchQuery"
                @input="updateValue($event)"
                :placeholder="placeholder"
                :icon="icon"
                :icon-right="clearIcon"
                :icon-right-clickable="true"
                clearable>
            </b-input>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: "SearchInput",
        props: {
            value: String,
            minSearchLength: { type: Number, default: 3 },
            placeholder: { type: String, default: "Busqueda"},
            icon: { type: String, default: "magnify"}
        },
        data() {
            return {
                searchQuery: null
            }
        },
        created() {
            this.searchQuery = this.value
        },
        computed: {
            clearIcon() {
                return this.searchQuery && this.searchQuery.length > 1 ? 'fa-times-circle' : '';
            }
        },
        methods: {
            updateValue(value) {
                if (value.lenght > this.minSearchLength) {
                    this.searchQuery = '';
                } else {
                    this.searchQuery = value;
                }
                this.$emit('input', this.searchQuery);
            }
        }
    }
</script>

<style scoped>

</style>
