<template>
    <aside class="main-navigation">

       <menu-item url="/specials"
                   title="Especiales"
                   accessory-url="/specials/edit"
                   accessory-title="Nuevo Especial"
                   icon-class="fal fa-sparkles"/>

        <menu-item url="/shorturls"
                   title="Acortador de URL"
                   accessory-url="/acortador-urls/edit"
                   accessory-title="Nuevo URL"
                   icon-class="fal fa-link"/>

        <menu-item url="/ads"
                   title="Publicidad"
                   icon-class="fal fa-megaphone"
                   accessory-url="/ads/edit"
                   accessory-title="Nueva Publicidad"
        />
        <menu-item url="/users"
                   title="Usuarios"
                   icon-class="fal fa-user-friends"
                   accessory-url="/users/edit"
                   accessory-title="Nuevo Usuario"
        />
        <img class="img--menu" src="/img/logo-crossmedia.svg" alt="">

    </aside>

</template>
<script>
    export default {
        data() {
            return {
                isActive: true,
                expanded: {
                    elementos: false,
                    portadas: false
                }
            }
        }
    }
</script>
<style lang="scss">
    @import '../../../sass/theme';

    aside.main-navigation {
        display: block;
        width: 225px;
        margin-top: 74px;

        .menu-item, .collapse-trigger a {
            //display: inline-block;
            min-height: 47px;
            width: 100%;

            /*            display: flex;*/
            flex: 1 0;
            flex-direction: row;
            color: white;
            padding: 15px;
        }

        .img--menu {
            position: absolute;
            bottom: 9px;
            left: 36px;
        }

        .menu-item {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            box-sizing: border-box;
            color: white;
            padding-left: 1em;

            .item-label {
                position: absolute;
                height: 47px;
                top: 0px;
                bottom: 0;
                left: 15px;
                right: 15%;
                z-index: 1;
                width: 100%;
                cursor: pointer;
                color: white;
                font-size: 14px;
                font-weight: 600;
                padding: 9px 0;

                vertical-align: middle;
                border-radius: 0 18px 18px 0px;

                .icon {
                    margin-top: 2px;
                    margin-right: 11px;
                    font-size: 16px;
                }
            }

            .item-accessory {
                display: inline-block;
                width: 35px;
                position: absolute;
                top: 10px;
                right: 0;
                bottom: 0;
                z-index: 10;
                cursor: pointer;
                background: transparent;
                color: #d2d2d2;
                opacity: 0;
                vertical-align: middle;
                text-align: center;
                //
                &:hover {
                    /*                    background-color: $accentColor;*/
                    color: white;
                }

                .icon {
                    margin-top: 2px;
                }
            }

            .accessory-title {
                display: none;
            }

            &:hover {
                border-radius: 0 18px 18px 0px;
                background: rgba(0, 0, 0, 0.1);

                .item-label {
                    /*                    background: white;*/
                    color: white;
                }

                .item-accessory {
                    opacity: 1;
                }
            }

            &:hover > a.item-label:not(:hover) {
                .item-label {
                    /*                    background: blue;*/
                    color: white;
                }

                .menu-title {
                    display: none;
                }

                .accessory-title {
                    display: inline-block;
                }
            }
        }

        .collapse {
            display: block;
            position: relative;
            box-sizing: border-box;
            color: white;
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            /*            padding: 2px 0 0 15px;*/
            min-height: 47px;

            &:hover {
                border-radius: 0 18px 18px 0px;
                background: rgba(0, 0, 0, 0.1);
            }

            .collapse-content {
                display: inherit;
                margin-top: 10px;
            }
        }

        .collapse .container {
            background: rgba(0, 0, 0, .035);

            .menu-item .item-label {
                left: 0;
                padding: 9px 15px;
            }
        }

        .collapse-trigger {
            .fa-chevron-right {
                display: inline-flex;
            }

            .fa-chevron-down {
                display: none;
            }

            .icon {
                margin-top: 2px;
                margin-right: 11px;
                font-size: 16px;
            }
        }

        .collapse-trigger .open {
            .fa-chevron-right {
                display: none;
            }

            .fa-chevron-down {
                display: inline-flex;
            }
        }
    }
</style>
