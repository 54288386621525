<template>
    <div class="form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>

            <b-message>
                Complete todos los metadatos requieridos y presione 'Guardar'
            </b-message>

            <b-field label="* Titulo"
                     :type="errors.type('title')"
                     :message="errors.get('title')">
                <b-input
                    name="title"
                    type="text"
                    v-model="form.title"
                    @change="bindSlug(form.title)"
                    @input="bindSlug(form.title)"
                    expanded
                />
            </b-field>

            <b-field label="Url">
                <slug-input
                    ref="slug"
                    v-model="form.url"
                    prefix="https://apuntmedia.es/../"
                    :hide-sync="isEditing"
                    :type="errors.type('url')"
                    :message="errors.get('url')"
                    name="slug"></slug-input>
            </b-field>

            <b-field label="Descripcion"
                     :type="errors.type('description')"
                     :message="errors.get('description')">
                <summernote class="form-control"
                            name="editor"
                            v-model="form.description"
                ></summernote>
            </b-field>

            <b-message>
                <strong>Importante: </strong>Los assets no se publicaran en la platforma si su fecha de activacion esta
                en el futuro o si la fecha de desactivation ha expirado
            </b-message>

            <b-field label="Fecha de publicacion"
                     :type="errors.type('publishing_date')"
                     :message="errors.get('publishing_date')">
                <calendar-input
                    v-model="form.publishing_date">
                </calendar-input>
            </b-field>

            <b-field label="Fecha de finalizacion"
                     :type="errors.type('expiration_date')"
                     :message="errors.get('expiration_date')">
                <calendar-input
                    v-model="form.expiration_date">
                </calendar-input>
            </b-field>

            <b-field label="Tipo de especial"
                     :type="errors.type('page_type')"
                     :message="errors.get('page_type')">
                <section>
                    <div class="block">
                        <b-radio v-model="form.page_type"
                                 native-value="radio">
                            Radio
                        </b-radio>
                        <b-radio v-model="form.page_type"
                                 native-value="tv">
                            Tv
                        </b-radio>

                    </div>
                </section>
            </b-field>

            <b-field label="Id del player"
                     :type="errors.type('player_bc')"
                     :message="errors.get('player_bc')">
                <async-select
                    :multiple="true"
                    v-model="form.player_bc"
                    data-url="/bcapi/players-list"
                />
            </b-field>

            <b-field label="Id Externo"
                     :type="errors.type('bc_id')"
                     :message="errors.get('bc_id')">
                <async-select
                    :multiple="true"
                    v-model="form.bc_id"
                    data-url="/bcapi/live-list"
                />
            </b-field>

            <b-field label="URL m3u8" v-if="form.url_m3u8">
                <b-message class="is-link slug-preview">
                    {{ form.url_m3u8 }}
                </b-message>
            </b-field>

            <b-field label="Ad Keys"
                     :type="errors.type('ad_keys')"
                     :message="errors.get('ad_keys')">
                <b-input
                    name="ad_keys"
                    type="text"
                    v-model="form.ad_keys"
                    expanded
                />
            </b-field>

            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button tag="a"
                                      href="/specials">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import moment from 'moment';
    import SlugInput from "../fields/SlugInput";
    import Summernote from '../../lib/Summernote';
    import VideoIdSelector from "../fields/VideoIdSelector";
    import AsyncSelect from "../fields/AsyncSelect";
    import CalendarInput from "../fields/CalendarInput";

    export default {
        name: "SpecialsForm",
        components: {CalendarInput, SlugInput, VideoIdSelector, AsyncSelect, Summernote},
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                id: null,
                form: {
                    title: null,
                    url: null,
                    description: null,
                    player_bc: 'default',
                    bc_id: null,
                    url_m3u8: null,
                    ad_keys: null,
                    publishing_date: new Date(),
                    expiration_date: moment().add(1, 'years').toDate(),
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values && this.values.id) {
                this.id = this.values.id;

                this.form = {...this.form, ...this.values}
                this.form.publishing_date = new Date(this.form.publishing_date)
                this.form.expiration_date = new Date(this.form.expiration_date)
                this.form.player_bc = this.form.player_bc || 'default';
            }
        },
        computed: {
            isEditing() {
                return this.id !== null
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();

                    formData.append('title', this.form.title || '');
                    formData.append('url', this.form.url || '');
                    formData.append('description', this.form.description || "");

                    formData.append('publishing_date', moment(this.form.publishing_date).format());
                    formData.append('expiration_date', moment(this.form.expiration_date).format());
                    formData.append('page_type', this.form.page_type || '');
                    formData.append('player_bc', this.form.player_bc || '');
                    formData.append('bc_id', this.form.bc_id || '');
                    formData.append('ad_keys', this.form.ad_keys || '');

                    let url = `/specials/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    this.errors.reset();

                    this.$buefy.toast.open({
                        message: 'El directo ha sido guardada',
                        type: 'is-success'
                    });

                    this.form.url_m3u8 = result.data.values.url_m3u8
                    if (!this.id && result.data.id) {
                        setTimeout(() => {
                            let toks = window.location.href.split('/');
                            toks.push(result.data.id);
                            window.location.href = toks.join('/');
                        }, 2000);
                    } else {
                        this.submitting = false;
                    }
                } catch (error) {
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        console.log(error.response.data.errors)
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Hay errores en el formulario',
                        type: 'is-danger'
                    });
                }
            },
            bindSlug(value) {
                if (!this.isEditing) {
                    this.$refs.slug.syncSlug(value)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";
</style>
