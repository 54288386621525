<template>
    <div class="video_id_selector">
        <b-field>
            <b-input
                :id="id"
                type="text"
                :name="id"
                :value="videoId"
                @input="updateValue({id: $event})"
            />
            <p class="control">
                <b-button @click="isSelecting = true" class="button">Seleccionar...</b-button>
            </p>
        </b-field>
        <b-modal :active.sync="isSelecting"
                 full-screen
                 has-modal-card :can-cancel="false">
            <modal-form :value="itemSelected" @input="updateValue($event)" :data-url="dataUrl"></modal-form>
        </b-modal>
    </div>
</template>

<script>
    import {Local} from "../../lib/Request";

    const ModalForm = {
        name: "ModalForm",
        props: {
            dataUrl: {required: true, default: null},
            value: {type: Object, default: null}
        },
        template: `
            <div class="modal-card" style="width: auto">
                <section class="modal-card-body">
                    <news-listing
                        :data-url="dataUrl"
                        v-model="value"
                        :cbSelected="onSelected"
                        :selector="true"
                        :show-type="true"
                    ></news-listing>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="$parent.close()">Cerrar</button>
                </footer>
            </div>
        `,
        methods: {
            onSelected(value) {
                this.$emit('input', value)
                this.$parent.close()
            }
        }
    }

    export default {
        name: "VideoIdSelector",
        components: {
            ModalForm
        },
        props: {
            id: String,
            dataUrl: {required: true, default: null},
            value: {required: false, default: null}
        },
        data() {
            return {
                itemSelected: {},
                videoId: null,
                options: [],
                isSelecting: false,
            }
        },
        created() {
        },
        mounted() {
            this.videoId = this.value
        },
        methods: {
            updateValue(value) {
                this.videoId = value.id
                this.$emit('input', value.id)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/theme';

</style>
