var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form media-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Posición",
              type: _vm.errors.type("position"),
              message: _vm.errors.get("position")
            }
          },
          [
            _c(
              "b-select",
              {
                attrs: { name: "position", required: "" },
                model: {
                  value: _vm.form.position,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "position", $$v)
                  },
                  expression: "form.position"
                }
              },
              [
                _c("option", { attrs: { value: "preroll" } }, [
                  _vm._v("Preroll")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "midroll" } }, [
                  _vm._v("Midroll")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "postroll" } }, [
                  _vm._v("Postroll")
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "* Titulo",
              type: _vm.errors.type("title"),
              message: _vm.errors.get("title")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "title", type: "text", expanded: "" },
              model: {
                value: _vm.form.title,
                callback: function($$v) {
                  _vm.$set(_vm.form, "title", $$v)
                },
                expression: "form.title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Descripcion",
              type: _vm.errors.type("description"),
              message: _vm.errors.get("description")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "description", type: "text", expanded: "" },
              model: {
                value: _vm.form.description,
                callback: function($$v) {
                  _vm.$set(_vm.form, "description", $$v)
                },
                expression: "form.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Skip Time (mm:ss)",
              type: _vm.errors.type("skip_time"),
              message: _vm.errors.get("skip_time")
            }
          },
          [
            _c("time-input", {
              attrs: { name: "skip_time" },
              model: {
                value: _vm.form.skip_time,
                callback: function($$v) {
                  _vm.$set(_vm.form, "skip_time", $$v)
                },
                expression: "form.skip_time"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Publicidad / Bc Id",
              type: _vm.errors.type("bc_id"),
              message: _vm.errors.get("bc_id")
            }
          },
          [
            _c("async-select", {
              attrs: {
                multiple: true,
                value: _vm.form.bc_id,
                "data-url": "/bcapi/ads-list"
              },
              on: { input: _vm.fetchDuration }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.form.bc_id
          ? _c(
              "b-field",
              {
                attrs: {
                  label: "Duración (mm:ss)",
                  type: _vm.errors.type("duration"),
                  message: _vm.errors.get("duration")
                }
              },
              [
                _c("time-input", {
                  attrs: { name: "duration", editable: false },
                  model: {
                    value: _vm.form.duration,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "duration", $$v)
                    },
                    expression: "form.duration"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Url",
              type: _vm.errors.type("url"),
              message: _vm.errors.get("url")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "url", type: "text", expanded: "", required: "" },
              model: {
                value: _vm.form.url,
                callback: function($$v) {
                  _vm.$set(_vm.form, "url", $$v)
                },
                expression: "form.url"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Fecha de publicacion",
              type: _vm.errors.type("start_at"),
              message: _vm.errors.get("start_at")
            }
          },
          [
            _c("calendar-input", {
              model: {
                value: _vm.form.start_at,
                callback: function($$v) {
                  _vm.$set(_vm.form, "start_at", $$v)
                },
                expression: "form.start_at"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Fecha de finalizacion",
              type: _vm.errors.type("end_at"),
              message: _vm.errors.get("end_at")
            }
          },
          [
            _c("calendar-input", {
              model: {
                value: _vm.form.end_at,
                callback: function($$v) {
                  _vm.$set(_vm.form, "end_at", $$v)
                },
                expression: "form.end_at"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "b-tabs",
          { attrs: { type: "is-boxed" } },
          [
            _c(
              "b-tab-item",
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("b-icon", { attrs: { icon: "source-pull" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _vm._v(" Especiales "),
                        _c("b-tag", { attrs: { rounded: "" } }, [
                          _vm._v(" " + _vm._s(_vm.form.specials.length) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("specials-listing", {
                  attrs: {
                    "data-url": "/specials/listing",
                    values: _vm.form.specials,
                    cbSelected: function(values) {
                      this$1.form.specials = values
                    },
                    "show-type": true,
                    selector: true,
                    multiple: true,
                    query: { hasBcId: 1, showAll: 1 }
                  }
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "b-tab-item",
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("b-icon", { attrs: { icon: "information-outline" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _vm._v(" Media Assets "),
                        _c("b-tag", { attrs: { rounded: "" } }, [
                          _vm._v(" " + _vm._s(_vm.form.assets.length) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("news-listing", {
                  attrs: {
                    "data-url": "/news/listing/audio,video",
                    values: _vm.form.assets,
                    cbSelected: function(values) {
                      this$1.form.assets = values
                    },
                    "show-type": true,
                    selector: true,
                    multiple: true,
                    query: { hasBcId: 1 }
                  }
                })
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                            * Campos Obligatorios\n                        "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/media" } }, [
                    _vm._v("Cancelar\n                            ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                            ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }