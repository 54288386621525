var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          attrs: {
            position: "is-right",
            type: "is-light",
            multilined: "",
            label: "message"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
          _c("span", { staticClass: "icon" }, [
            _c("i", { staticClass: "far fa-question-circle" })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }