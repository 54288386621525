var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      { attrs: { href: "/" + _vm.resource + "/edit/" + _vm.id } },
      [_c("b-icon", { attrs: { icon: "pencil" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        on: {
          click: function($event) {
            return _vm.deleteResource(_vm.id)
          }
        }
      },
      [_c("b-icon", { attrs: { icon: "delete" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }