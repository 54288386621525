import { render, staticRenderFns } from "./ImageUploadBox.vue?vue&type=template&id=f57db92c&scoped=true&"
import script from "./ImageUploadBox.vue?vue&type=script&lang=js&"
export * from "./ImageUploadBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f57db92c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/xcms-lite/release-1.1.x/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f57db92c')) {
      api.createRecord('f57db92c', component.options)
    } else {
      api.reload('f57db92c', component.options)
    }
    module.hot.accept("./ImageUploadBox.vue?vue&type=template&id=f57db92c&scoped=true&", function () {
      api.rerender('f57db92c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/fields/ImageUploadBox.vue"
export default component.exports