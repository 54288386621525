<template>
    <div class="menu-item">
        <a :href="url" :onclick="onClick" class="item-label">
            <span class="icon"><i :class="iconClass"/></span>
            <span class="menu-title">{{title}}</span>
            <span class="accessory-title">{{accessoryTitle || title}}</span>
        </a>
        <a v-if="accessoryUrl" class="item-accessory" :href="accessoryUrl">
            <span class="icon"><i :class="accessoryClass"/></span>
        </a>
    </div>
</template>

<script>
    export default {
        name: "MenuItem",
        props: {
            url: {required: false, default: '#'},
            onClick: {required: false, default: null},
            title: {required: false, default: 'title'},
            iconClass: {required: false, default: 'fab fa-youtube'},
            accessoryUrl: {required: false, default: null},
            accessoryTitle: {required: false, default: null},
            accessoryClass: {required: false, default: 'fal fa-plus'}
        },
        data() {
            return {}
        },
        displayAccessoryLabel() {
            alert('hey!')
        }
    }
</script>

<style scoped>

</style>
