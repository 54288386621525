<template>
    <div class="form">
        <form @submit.prevent="nosubmit" enctype="multipart/form-data">
            <slot name="csrf"/>
            Anadir un XML de programacion
            <flash-box
                :message="{
                    type: 'is-info',
                    text:'Selecciona un archivo XML correspondiente a la programacion de un dia que quieras anadir.'
                }"
            />
            <b-field label="Tipo de programacion">
                <b-radio v-model="form.type"
                    name="type"
                    native-value="tv"
                    required>
                    Tv
                </b-radio>
                <b-radio v-model="form.type"
                    name="type"
                    native-value="radio">
                    Radio
                </b-radio>
                <b-radio v-model="form.type"
                    name="type"
                    native-value="internet">
                    Internet
                </b-radio>
            </b-field>

            <b-field label="Archivo XML">
                <b-upload v-model="form.file"
                    required
                    accept=".xml"
                    >
                    <a class="button is-primary">
                        <span>Examinar</span>
                    </a>
                </b-upload>
            </b-field>

            <div v-if="form.file && form.file.name" class="tags">
                <span
                    class="tag is-primary" >
                    {{form.file.name}}
                    <button class="delete is-small"
                            type="button"
                            @click="deleteFile()">
                    </button>
                </span>
            </div>



            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                type="is-success"
                                native-type="button"
                                @click="submit"
                                :disabled="submitting || !(form.file && form.file.name)"
                            >
                                Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    export default {
        name: "ProgrammingForm",
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                form: {
                }
            }
        },
        methods: {
            deleteFile() {
                this.form.file = null;
            },
            async submit(){
                if (this.submitting){
                    return;
                }
                try {
                    this.submitting = true;

                    const result = await Local.put('', this.form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (!(result && result.data && result.data.success)){
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'Item saved',
                        type: 'is-success'
                    });
                    this.errors.reset();

                    this.submitting = false;

                } catch (error) {
                    this.submitting=false;
                    console.error(error);
                    if (error && error.response && error.response.data && error.response.data.errors){
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Something went wrong',
                        type: 'is-danger'
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
</style>
