<template>
    <div>
        <b-button @click="openEdit(null)">Nueva Imagen</b-button>
        <draggable :list="data" @end="reorder" :disabled=moving>
            <div class="card item" v-for="element in data" :key="element.id">
                <div class="card-image">
                    <figure class="image">
                        <img class="carousel-image" :src="element.image"/>
                    </figure>
                </div>
                <div class="card-content">
                    <div class="content">
                        <div class="media-content">
                            <p class="subtitle">#{{ element.position }}</p>
                            <p class="title is-4">{{ element.name }}</p>
                            <footer class="level">
                                <div class="level-left">
                                    <b-switch
                                        name="status"
                                        true-value=1
                                        false-value=0
                                        @input="updateStatus(element.id, $event)"
                                        v-model="element.status">
                                    </b-switch>
                                </div>
                                <div class="level-right">
                                    <div class="level-item">
                                    <b-button
                                        class="is-danger"
                                        icon="cancel"
                                        @click="destroy(element.id)">Eliminar</b-button></div>&nbsp;
                                    <div class="level-item">
                                    <b-button
                                        icon="pencil"
                                        @click="openEdit(element.id)">Editar</b-button></div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </draggable>
        <b-modal :active.sync="isEditing"
                 width="1100"
                 has-modal-card
                 ref="modalForm"
                 :can-cancel="true">
            <div class="container form-wrapper">
                <modal-form v-model="id" :carousel-id="carousel_id"></modal-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    import draggable from 'vuedraggable'

    const ModalForm = {
        name: "ModalForm",
        props: {
            value: { type: Number, default: null },
            carouselId: { type: Number, required: true }
        },
        template: `
            <div class="modal-card" style="min-width: 720px">
                <section class="modal-card-body">
                    <carousel-form :id="id" :carousel-id="carouselId"></carousel-form>
                </section>
            </div>
        `,
        data() {
            return {
                id: null
            }
        },
        mounted() {
            this.id = this.value
            console.log(this.carouselId);
        },
        methods: {}
    }

    export default {
        name: "CarouselsListing",
        components: {
            draggable, ModalForm
        },
        props: {
            carousel_id: {required: false, default: 1}
        },
        data: () => {
            return {
                data: [],
                previous: [],
                moving: false,
                isEditing: false,
                id: null
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            async fetchData() {
                try {
                    const result = await Local.get('/carousel/listing', {
                        params: {
                            'carousel_id': this.carousel_id,
                        }
                    });
                    this.data = result.data.data;
                    this.previous = _.cloneDeep(this.data);
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            },
            openEdit(id) {
                this.id = id;
                this.isEditing = true;
            },
            async reorder() {
                this.moving = true;
                let toUpdate = [];
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].position !== this.previous[i].position) {
                        toUpdate.push({
                            id: this.data[i].id,
                            position: i + 1
                        });
                        this.data[i].position = i + 1;
                    }
                }
                console.log(toUpdate);
                if (toUpdate.length) {
                    try {
                        const response = await Local.post('/carousel/position', toUpdate);
                        this.previous = _.cloneDeep(this.data);
                    } catch (error) {
                        console.error(error);
                        this.$buefy.toast.open({
                            duration: 5000,
                            closable: true,
                            message: 'El orden no ha sido actualizado',
                            type: 'is-danger'
                        });
                        this.data = _.cloneDeep(this.previous);
                    }
                }
                this.moving = false;
            },
            onMoveCallback(evt, originalEvent) {
                console.log(evt);
            },
            async updateStatus(id, value) {
                console.log(value)
                try {
                    await Local.post(`/carousel/enable/${id}`);
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            },
            async destroy(id) {
                try {
                    await Local.post(`/carousel/delete`, {
                        'id': id
                    });

                    setTimeout(() => {
                        window.location.href = `${window.location.href}`;
                    }, 100);
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            }
        }
    }

</script>
<style lang="scss" scoped>
    .carousel-page {
        .content .image {
            margin-left: 0;
            margin-right: 0;
        }

        .card-image {
            max-height: 250px;
            overflow: hidden;
        }

        .item {
            display: inline-block;
            margin: 10px;
            width: 45%;
        }

        .carousel-image {
            width: 100%;
            height: auto;
        }

        .media-content footer {
            text-align: right;
        }
    }
</style>
