var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("treeselect", {
        attrs: {
          flat: _vm.flat,
          multiple: _vm.multiple,
          "open-on-click": true,
          "open-on-focus": true,
          clearable: false,
          value: _vm.selectedValues,
          options: _vm.options
        },
        on: {
          input: function($event) {
            return _vm.updateValue($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "value-label",
            fn: function(ref) {
              var node = ref.node
              return _c("div", {}, [
                _vm._v("(" + _vm._s(node.id) + ") " + _vm._s(node.label))
              ])
            }
          },
          {
            key: "option-label",
            fn: function(ref) {
              var node = ref.node
              var shouldShowCount = ref.shouldShowCount
              var count = ref.count
              var labelClassName = ref.labelClassName
              var countClassName = ref.countClassName
              return _c("label", { class: labelClassName }, [
                _vm._v(
                  "\n            (" +
                    _vm._s(node.id) +
                    ") " +
                    _vm._s(node.label) +
                    "\n            "
                ),
                shouldShowCount
                  ? _c("span", { class: countClassName }, [
                      _vm._v(_vm._s(count))
                    ])
                  : _vm._e()
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }