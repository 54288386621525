var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-datetimepicker",
    {
      attrs: {
        value: _vm.value,
        placeholder: "",
        icon: "calendar-today",
        position: "is-top-right",
        "datetime-formatter": _vm.formatDate,
        "datetime-parser": _vm.parseDate,
        editable: "",
        datepicker: {
          "first-day-of-week": 1,
          "years-range": [-5, 20]
        }
      },
      on: {
        input: function($event) {
          return _vm.updateValue($event)
        }
      }
    },
    [
      _c(
        "template",
        { slot: "left" },
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              on: {
                click: function($event) {
                  _vm.updateValue(new Date())
                }
              }
            },
            [
              _c("b-icon", { attrs: { icon: "clock" } }),
              _vm._v(" "),
              _c("span", [_vm._v("Hoy")])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }