var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form labels-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns" },
          [
            _c(
              "b-field",
              {
                staticClass: "column",
                attrs: {
                  label: "Thumbnail",
                  type: "is-danger",
                  type: _vm.errors.type("thumbnail"),
                  message: _vm.errors.get("thumbnail")
                }
              },
              [
                _c("image-upload-box", {
                  ref: "thumbnail",
                  attrs: { "default-url": _vm.form.thumbnail },
                  model: {
                    value: _vm.thumbData,
                    callback: function($$v) {
                      _vm.thumbData = $$v
                    },
                    expression: "thumbData"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "column",
                attrs: {
                  label: "Destacados",
                  type: _vm.errors.type("video_asset"),
                  message: _vm.errors.get("video_asset")
                }
              },
              [
                _c("video-id-selector", {
                  attrs: {
                    id: "video_asset",
                    name: "video_asset",
                    "data-url": "/news/listing/noticias,video,audio"
                  },
                  model: {
                    value: _vm.form.video_asset,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "video_asset", $$v)
                    },
                    expression: "form.video_asset"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Nombre",
              type: _vm.errors.type("name"),
              message: _vm.errors.get("name")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text", name: "name", maxlength: "64" },
              model: {
                value: _vm.form.name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Padre Label",
              type: _vm.errors.type("parent_id"),
              message: _vm.errors.get("parent_id")
            }
          },
          [
            _c("multi-select", {
              attrs: {
                multiple: false,
                flat: false,
                "data-url": "/labels/listing"
              },
              on: {
                input: function($event) {
                  return _vm.fetchSlug($event)
                }
              },
              model: {
                value: _vm.form.parent_id,
                callback: function($$v) {
                  _vm.$set(_vm.form, "parent_id", $$v)
                },
                expression: "form.parent_id"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Url" } },
          [
            _c("slug-input", {
              ref: "slug",
              attrs: {
                prefix: _vm.labelPrefix,
                "allow-slash": true,
                "force-root-slash": true,
                "hide-sync": true,
                type: _vm.errors.type("full_name"),
                message: _vm.errors.get("full_name"),
                name: "full_name"
              },
              model: {
                value: _vm.form.full_name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "full_name", $$v)
                },
                expression: "form.full_name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "field-options", attrs: { label: "Visible en" } },
          [
            _c("nav", { staticClass: "level" }, [
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Smart TV" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "smarttv_visible",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.smarttv_visible,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "smarttv_visible", $$v)
                          },
                          expression: "form.smarttv_visible"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Movíl" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "mobile_visible",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.mobile_visible,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mobile_visible", $$v)
                          },
                          expression: "form.mobile_visible"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Web" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "web_visible",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.web_visible,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "web_visible", $$v)
                          },
                          expression: "form.web_visible"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "field-options", attrs: { label: "Flags" } },
          [
            _c("nav", { staticClass: "level" }, [
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Es un link" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "is_link",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.is_link,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_link", $$v)
                          },
                          expression: "form.is_link"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Es audio" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "is_audio",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.is_audio,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_audio", $$v)
                          },
                          expression: "form.is_audio"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Es noticia" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "is_news",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.is_news,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_news", $$v)
                          },
                          expression: "form.is_news"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item has-text-centered" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Asistencia de público" } },
                    [
                      _c("b-switch", {
                        attrs: {
                          name: "real_public_assistance",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        model: {
                          value: _vm.form.real_public_assistance,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "real_public_assistance", $$v)
                          },
                          expression: "form.real_public_assistance"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "iTunes Podcast url",
              type: _vm.errors.type("itunes_url"),
              message: _vm.errors.get("itunes_url")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text", name: "itunes_url", maxlength: "100" },
              model: {
                value: _vm.form.itunes_url,
                callback: function($$v) {
                  _vm.$set(_vm.form, "itunes_url", $$v)
                },
                expression: "form.itunes_url"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Ivoox Podcast url",
              type: _vm.errors.type("ivoox_url"),
              message: _vm.errors.get("ivoox_url")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text", name: "ivoox_url", maxlength: "100" },
              model: {
                value: _vm.form.ivoox_url,
                callback: function($$v) {
                  _vm.$set(_vm.form, "ivoox_url", $$v)
                },
                expression: "form.ivoox_url"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Info url",
              type: _vm.errors.type("info_url"),
              message: _vm.errors.get("info_url")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text", name: "info_url", maxlength: "100" },
              model: {
                value: _vm.form.info_url,
                callback: function($$v) {
                  _vm.$set(_vm.form, "info_url", $$v)
                },
                expression: "form.info_url"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Color de fondo",
              type: _vm.errors.type("bc_color"),
              message: _vm.errors.get("bg_color")
            }
          },
          [
            _c("color-input", {
              attrs: { name: "bg_color" },
              model: {
                value: _vm.form.bg_color,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bg_color", $$v)
                },
                expression: "form.bg_color"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Color de texto",
              type: _vm.errors.type("txt_color"),
              message: _vm.errors.get("txt_color")
            }
          },
          [
            _c("color-input", {
              attrs: { name: "txt_color" },
              model: {
                value: _vm.form.txt_color,
                callback: function($$v) {
                  _vm.$set(_vm.form, "txt_color", $$v)
                },
                expression: "form.txt_color"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Programes A-Z",
              type: _vm.errors.type("is_item"),
              message: _vm.errors.get("is_item")
            }
          },
          [
            _c(
              "b-select",
              {
                attrs: { name: "is_item", expanded: "" },
                model: {
                  value: _vm.form.is_item,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "is_item", $$v)
                  },
                  expression: "form.is_item"
                }
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v("No aparece en Programes A-Z")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v("Aparece como item")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "2" } }, [
                  _vm._v("Muestra su contenido")
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Descripcion",
              type: _vm.errors.type("description"),
              message: _vm.errors.get("description")
            }
          },
          [
            _c("b-input", {
              attrs: { type: "textarea", name: "description" },
              model: {
                value: _vm.form.description,
                callback: function($$v) {
                  _vm.$set(_vm.form, "description", $$v)
                },
                expression: "form.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Layout" } },
          [
            _vm.values.protected
              ? _c("p", [_vm._v(_vm._s(_vm.values.layout))])
              : _vm._e(),
            _vm._v(" "),
            !_vm.values.protected
              ? _c(
                  "b-select",
                  {
                    model: {
                      value: _vm.form.layout,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "layout", $$v)
                      },
                      expression: "form.layout"
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "parent_with_principal" } },
                      [_vm._v("Grid con descripción")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "grid" } }, [
                      _vm._v("Grid")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "entertainment" } }, [
                      _vm._v("Entertainment")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "news_home" } }, [
                      _vm._v("News Home")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "parent_automatic" } }, [
                      _vm._v("Parent Auto")
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/labels" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }