<template>
    <div class="thumb-box">
        <b-upload ref="upload" v-on:input="updateValue($event)" :accept="accept" :multiple="false">
            <a class="button is-primary">
                <span>Examinar...</span>
            </a>
        </b-upload>
        <div>
            <img :src="previewImageUrl" class="preview" v-if="previewImageUrl"/>
        </div>
        <footer v-if="image && image.name" class="tags">
            <div
                class="tag is-primary">
                <b-icon icon="upload"></b-icon>
                {{image.name}}
                <button class="delete is-small"
                        type="button"
                        @click="deleteThumbnail()">
                </button>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: "ImageUploadBox",
        props: {
            value: File,
            defaultUrl: String,
            accept: {default: "image/*"},
        },
        data() {
            return {
                image: null,
                previewImageData: null,
                previewUrl: this.defaultUrl || null
            }
        },
        mounted() {
            this.previewUrl = this.defaultUrl
        },
        computed: {
            previewImageUrl() {
                if (this.previewImageData) {
                    return this.previewImageData
                }
                if (this.previewUrl) {
                    return `${this.previewUrl}?${Date.now()}`
                }
                return 'https://statics.apuntmedia.es/APUNTDEFAULT.jpg';
            }
        },
        methods: {
            setPreviewUrl(url) {
                this.previewUrl = url
            },
            updateValue(file) {
                this.$emit('input', file)
                let reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = e => {
                    this.previewImageData = e.target.result
                }
                this.image = file
            },
            deleteThumbnail() {
                this.image = null
                this.previewImageData = null
            },
            reset(url) {
                this.deleteThumbnail()
                this.previewUrl = url
                console.log(url)
            }
        }
    }
</script>

<style scoped>

</style>
