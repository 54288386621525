<template>
    <div class="content-wrapper">
        <div class="slide-menu">
            <main-nav />
        </div>

        <input type="checkbox" id="nav-trigger" class="nav-trigger" />
<!--        <label for="nav-trigger" />-->
        <section class="page-content">
            <slot name="contentheader" />
            <article class="content">
             
<!--
             <div class="header-content--box"><h1 class="title">{{ title }}</h1>
                <nav class="breadcrumbs">
                    <slot name="breadcrumbs"/>
                </nav>
                </div>
-->
              <img class="welcome--logo" src="/img/logo-der.svg" alt="">
               <img class="welcome--img" src="/img/bg-bienvenido.png" alt="">
                <slot />
            </article>
            <footer> <div class="text--footer">XCMS by crossmedia</div>
            <div class="logo--footer"><img class="footer--img1" src="/img/logo_apunt_footer.svg" alt=""></div> </footer>
        </section>
    </div>
</template>

<script>
    export default {
        name: "AppContent",
        props: { title: String }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/theme';
    @import '../../../sass/variables';

    .content-wrapper {
        min-width: 100%;
        min-height: 100%;
/*        position: relative;*/
        top: 0;
        bottom: 100%;
        left: 0;
        display: flex;
    }

     .welcome--img, .welcome--logo{
            display: none;
    }

    .header-content--box {
        width: 100%;
        height: 74px;
        margin-bottom: 7px;
        background: white;
        border: solid 1px #d2d2d2;
    }

    .dashboard-page {
        .welcome--img{
            position: fixed;
            bottom: 40px;
            right: 0px;
            display: block;
            max-width: 589px;
            opacity: 0.4;
        }

        .welcome--logo{
            position: fixed;
            bottom: 140px;
            left: calc(250px + 100px);
            display: block;
            max-width: 188px;
        }
    }

    .text--footer{
        width: 163px;
        font-size: 14px;
        color: white;
        text-align: left;
        font-family: $font-family-sans-serif;
        margin-left: 20px;
        height: 40px;
        padding: 10px 0 0;
        float: left;
    }
    .logo--footer{
        width: 103px;
        float: right;
        padding: 10px 20px 0 0;
    }
    .page-content {
    background: #f8f8f8;
    height: 100vh;
        
    flex-direction: column;
    display: flex;
    flex: 1;
/*
    float: left;
    width: calc(100vw - 265px);
*/
    box-shadow: inset 4px 2px 10px -5px rgba(0, 0, 0, 0.75);
/*
        min-width: 100%;
        min-height: 100%;
        position: relative;
        top: 0;
        bottom: 100%;
        left: 0;
        z-index: 1;
*/

    }

    .slide-menu {
        list-style: none;
        background: #3161A3;
        background: linear-gradient(363deg, #3161A3 0%, #272758 100%);
        width: 250px;
        height: 100vh;
        flex-shrink: 0;
        display: flex;
/*
        position: relative;
        float: left;
*/
/*
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
*/
/*        padding: 2em 1em;    */
    }

    .nav-trigger {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        display: none;
    }

    label[for="nav-trigger"] {
        position: fixed;
        top: 21px;
        left: 43px;
        z-index: 10;
        width: 30px;
        height: 30px;
        cursor: pointer;

        /*        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='30px' height='30px' viewBox='0 0 30 30' enable-background='new 0 0 30 30' xml:space='preserve'><rect width='30' height='6'/><rect y='24' width='30' height='6'/><rect y='12' width='30' height='6'/></svg>");*/
        &::after {
            content: "\f0c9";
            width: 33px;
            height: 33px;
            display: inline-block;
            font-weight: 300;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: $font-awesome;
            font-size: 24px;
        }
    }

    .nav-trigger:checked+label {
/*        left: 275px;*/
    }

    .nav-trigger:checked~.page-content {
/*        left: 240px;*/
        -webkit-box-shadow: inset 4px 2px 10px -5px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: inset 4px 2px 10px -5px rgba(0, 0, 0, 0.75);
        box-shadow: inset 4px 2px 10px -5px rgba(0, 0, 0, 0.75);
    }

    .nav-trigger+label,
    .page-content {
        transition: left 0.5s ease-in-out;

    }

    footer{
        position: fixed;
        width: calc(100vw - 250px);
        height: 40px;
        background: #424347;
        bottom: 0;
    }

    article.content {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        height: 86vh;
        overflow-y: auto;
        overflow-x: hidden;
        width: 95%;
    }
</style>
