<template>
    <b-field>
        <b-select :value="selectedValue" @change.native="updateValue($event.target.value)" :loading="loading">
            <option v-for="option in options"
                    :value="option.id"
                    :key="option.id">
                ({{ option.id }}) {{ option.name }}
            </option>
        </b-select>
        <p class="control" v-if="canClear">
            <b-button class="button" @click="clearValue">
                <b-icon icon="eraser"></b-icon>
            </b-button>
        </p>
    </b-field>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {Local} from "../../lib/Request";

    export default {
        name: "AsyncSelect",
        components: {
            Treeselect
        },
        props: {
            value: {type: String, required: false, default: null},
            flat: {type: Boolean, default: false},
            dataUrl: {type: String, required: true},
            allowClear: {type: Boolean, default: false}
        },
        data() {
            return {
                loading: true,
                options: [],
                selectedValue: null
            }
        },
        created() {
            this.selectedValue = this.value
            this.fetchData()
        },
        computed: {
            canClear() {
                return this.allowClear && this.selectedValue != null
            }
        },
        methods: {
            fetchData() {
                Local.get(this.dataUrl, {
                    params: {}
                })
                    .then((result) => {
                        this.loading = false
                        this.options = result.data.data;
                        this.updateValue(this.value)
                    });
            },
            updateValue(value) {
                this.selectedValue = value
                this.$emit('input', value)
            },
            clearValue() {
                this.updateValue(null)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/theme';

</style>
