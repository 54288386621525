<template>
    <div class="form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>
            <div class="columns">

            </div>
            <b-field label="Titulo"
                     :message="errors.get('name')">
                <b-input
                    name="name"
                    type="text"
                    v-model="form.name"
                    required
                />
            </b-field>

            <b-field label="Titulo (linea 2)"
                     :message="errors.get('name_line_two')">
                <b-input
                    name="name_line_two"
                    type="text"
                    v-model="form.name_line_two"
                />
            </b-field>

            <b-field label="Descripción"
                     :message="errors.get('text')">
                <b-input
                    name="text"
                    type="text"
                    v-model="form.text"
                />
            </b-field>

            <b-field label="Link"
                     :message="errors.get('link')">
                <b-input
                    name="link"
                    type="text"
                    v-model="form.link"
                />
            </b-field>

            <b-field label="Image"
                     type="is-danger"
                     :message="errors.get('image')">
                <image-upload-box
                    ref="image"
                    :default-url="form.image"
                    v-model="imageData"/>
            </b-field>

            <b-field label="Color titulo 1"
                     :message="errors.get('color_titulo_1')">
                <color-input name="color_titulo_1" ref="color1" v-model="form.color_titulo_1"/>
            </b-field>

            <b-field label="Color titulo 2"
                     :message="errors.get('color_titulo_2')">
                <color-input name="color_titulo_2" ref="color2" v-model="form.color_titulo_2"/>
            </b-field>

            <b-field label="Color texto"
                     :message="errors.get('color_texto')">
                <color-input name="color_texto" ref="color3" v-model="form.color_texto"/>
            </b-field>

            <b-field label="Color bg movil"
                     :message="errors.get('color_bg_movil')">
                <color-input name="color_bg_movil" ref="color4" v-model="form.color_bg_movil"/>
            </b-field>

            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                class="is-success"
                                :loading="submitting">
                                Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>
    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import ImageUploadBox from "../fields/ImageUploadBox";

    export default {
        name: "CarouselForm",
        components: {
            ImageUploadBox
        },
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            id: {type: Number, default: null},
            carouselId: {type: Number, required: true}
            // errorMessages: {required: false, default: null},
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                imageData: null,
                form: {
                    id: null,
                    name: null,
                    name_line_two: null,
                    text: null,
                    link: null,
                    image: null,
                    color_titulo_1: null,
                    color_titulo_2: null,
                    color_texto: null,
                    color_bg_movil: null,
                    status: 0,
                }
            }
        },
        created() {
            // this.errors.setAll(this.errorMessages);
        },
        mounted() {
            setTimeout(() => {
                this.load(this.id)
            }, 100)
        },
        methods: {
            async load(id) {
                try {
                    const url = '/carousel/edit/' + (id || '')
                    const result = await Local.get(url);

                    if (result.data.values) {
                        this.form = result.data.values
                        this.$refs.image.setPreviewUrl(this.form.image);
                        this.$refs.color1.updateColor(this.form.color_titulo_1)
                        this.$refs.color2.updateColor(this.form.color_titulo_2)
                        this.$refs.color3.updateColor(this.form.color_texto)
                        this.$refs.color4.updateColor(this.form.color_bg_movil)
                        console.log(result.data.values);
                    }
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            },
            async submitForm() {
                try {
                    this.submitting = true;
                    console.log(this.form);

                    let formData = new FormData();

                    if (this.imageData) {
                        formData.append('imageFile', this.imageData);
                    }

                    formData.append('carousel_id', this.carouselId);
                    formData.append('name', this.form.name || '');
                    formData.append('name_line_two', this.form.name_line_two || '');
                    formData.append('text', this.form.text || '');
                    formData.append('link', this.form.link || '');
                    formData.append('color_titulo_1', this.form.color_titulo_1 || "");
                    formData.append('color_titulo_2', this.form.color_titulo_2 || "");
                    formData.append('color_texto', this.form.color_texto || "");
                    formData.append('color_bg_movil', this.form.color_bg_movil || "");

                    let url = `/carousel/edit`
                    if (this.form.id) {
                        url = `${url}/${this.form.id}`
                        formData.append('id', this.form.id);
                    }

                    const result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (!(result.data && result.data.id)) {
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'Carrusel actualizado',
                        type: 'is-success',
                        container: '.modal'
                    });

                    setTimeout(() => {
                        window.location.href = `${window.location.href}`;
                    }, 1000);
                } catch (error) {
                    this.submitting = false;
                    console.error(error);
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Something went wrong',
                        type: 'is-danger'
                    });
                }
            }
        }
    }

</script>

<style lang="scss">
    @import "../../../../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";

</style>
