<template>
    <span class="breadcrumb-item">
        <a :href="url">{{ title }}</a>
    </span>
</template>

<script>
    export default {
        name: "BreadcrumbItem",
        props: {
            title: { required: true },
            url: { required: true }
        }
    }
</script>
