var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            Complete todos los metadatos requieridos y presione 'Guardar'\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "* Titulo",
              type: _vm.errors.type("title"),
              message: _vm.errors.get("title")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "title", type: "text", expanded: "" },
              on: {
                change: function($event) {
                  return _vm.bindSlug(_vm.form.title)
                },
                input: function($event) {
                  return _vm.bindSlug(_vm.form.title)
                }
              },
              model: {
                value: _vm.form.title,
                callback: function($$v) {
                  _vm.$set(_vm.form, "title", $$v)
                },
                expression: "form.title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Url" } },
          [
            _c("slug-input", {
              ref: "slug",
              attrs: {
                prefix: "https://pool.apuntmedia.es/",
                "hide-sync": _vm.isEditing,
                type: _vm.errors.type("short_url"),
                message: _vm.errors.get("short_url"),
                name: "slug"
              },
              model: {
                value: _vm.form.short_url,
                callback: function($$v) {
                  _vm.$set(_vm.form, "short_url", $$v)
                },
                expression: "form.short_url"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Canal Brightcove",
              type: _vm.errors.type("bc_id"),
              message: _vm.errors.get("bc_id")
            }
          },
          [
            _c("async-select", {
              attrs: { multiple: true, "data-url": "/bcapi/live-list" },
              model: {
                value: _vm.form.bc_id,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bc_id", $$v)
                },
                expression: "form.bc_id"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.form.long_url
          ? _c(
              "b-field",
              { attrs: { label: "Long Url" } },
              [
                _c("b-message", { staticClass: "is-link slug-preview" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.form.long_url) +
                      "\n            "
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/shorturls" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }