var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form users-form" }, [
    _c(
      "form",
      {
        attrs: { autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { message: _vm.errors.get("first_name"), label: "Nombre" } },
          [
            _c("b-input", {
              attrs: { type: "text", name: "first_name" },
              model: {
                value: _vm.form.first_name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "first_name", $$v)
                },
                expression: "form.first_name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: { message: _vm.errors.get("last_name"), label: "Apellidos" }
          },
          [
            _c("b-input", {
              attrs: { type: "text", name: "last_name" },
              model: {
                value: _vm.form.last_name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "last_name", $$v)
                },
                expression: "form.last_name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { message: _vm.errors.get("email"), label: "Email" } },
          [
            _c("b-input", {
              attrs: { type: "email", name: "email", maxlength: "50" },
              model: {
                value: _vm.form.email,
                callback: function($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Password" } },
          [
            _c("b-input", {
              attrs: {
                type: "password",
                name: "password",
                autocomplete: "new-password",
                "password-reveal": ""
              },
              model: {
                value: _vm.form.password,
                callback: function($$v) {
                  _vm.$set(_vm.form, "password", $$v)
                },
                expression: "form.password"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("* Permisos")]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.permissions, function(permission) {
              return _c(
                "b-field",
                { key: permission.id },
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { "native-value": permission.id },
                      model: {
                        value: _vm.form.permissions,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "permissions", $$v)
                        },
                        expression: "form.permissions"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(permission.description) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        ]),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/news" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "is-success",
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }