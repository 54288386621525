<template>
    <nav class="tree-section">
        <div
            :class="{['tree-folder']: isFolder}"
            @dblclick="makeFolder">
            <div class="label level">
                <span class="level-left" @click="toggle">
                    <span class="icon" v-if="!orderMode">
                        <i :class="{'level-item': true, 'fas': true, ['fa-tags']: isFolder, ['fa-tag']: !isFolder}"/>
                    </span>
                    <span class="level-item icon handle" v-if="item.pid && nChilds > 1 && orderMode">
                        <i class="fa fa-align-justify"></i>
                    </span>
                     <span class="level-item">
                         ({{ item.id }}) {{ item.label }} <span v-if="isFolder"> [{{ isOpen ? '-' : '+' }}]</span>
                     </span>
                    <span class="level-item flags">
                        <span class="icon" v-if="item.smarttv_visible"><i class="fas fa-tv"></i></span>
                        <span class="icon" v-if="item.mobile_visible"><i class="fas fa-mobile-alt"></i></span>
                        <span class="icon" v-if="item.web_visible"><i class="fab fa-chrome"></i></span>
                        <span class="icon" v-if="item.is_link"><i class="fas fa-link"></i></span>
                        <span class="icon" v-if="item.is_news"><i class="far fa-newspaper"/></span>
                        <span class="icon" v-if="item.is_audio"><i class="fas fa-headphones-alt"/></span>
                    </span>
                </span>
                <span class="level-right">
                    <span class="level-item">
                        <a @click="openReorderModal(item.id)" class="button is-small">Ordenar Items</a>
                    </span>
                    <span class="level-item">
                        <a :href="editUrl" class="button is-small">Editar</a>
                    </span>
                </span>
            </div>
        </div>
        <div v-show="isOpen" v-if="isFolder" class="tree-children">
            <draggable :list="treeData" @end="doReorder()" handle=".handle">
                <tree-list
                    class="item tree-leaf"
                    v-for="(child, index) in item.children"
                    :key="index"
                    :item="child"
                    :nChilds="item.children.length"
                    :order-mode="orderMode"
                    @make-folder="$emit('make-folder', $event)"
                    @add-item="$emit('add-item', $event)"
                ></tree-list>
            </draggable>
        </div>
        <b-modal :active.sync="isReordering"
                 width="1100" full-screen
                 has-modal-card :can-cancel="true">
            <order-items-modal data-url="/labels/reorder/" :id="item.id"></order-items-modal>
        </b-modal>
    </nav>
</template>

<script>
    import draggable from 'vuedraggable'
    import OrderItemsModal from '../modals/OrderItemsModal'
    import {Local} from "../../lib/Request";

    export default {
        name: "TreeList",
        components: {
            draggable, OrderItemsModal
        },
        props: {
            item: Object,
            nChilds: Number,
            orderMode: Boolean
        },
        data() {
            return {
                treeData: [],
                isOpen: false,
                isReordering: false
            }
        },
        mounted() {
            this.treeData = this.item.children;
        },
        computed: {
            isFolder() {
                return this.item.children &&
                    this.item.children.length
            },
            editUrl() {
                return `/labels/edit/${this.item.id}`
            }
        },
        methods: {
            toggle() {
                if (this.isFolder) {
                    this.isOpen = !this.isOpen
                }
            },
            makeFolder() {
                if (!this.isFolder) {
                    this.$emit('make-folder', this.item)
                    this.isOpen = true
                }
            },
            doReorder() {
                const order = this.treeData.map((child) => {
                    return child.id
                })
                Local.post(`/labels/order/${this.item.id}`, {
                    ids: order
                })
            },
            openReorderModal(id) {
                this.isReordering = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/theme';

    .tree-section {
        margin-left: 1.5em;
        padding: 0.2em 0;

        & > div > .label {
            font-weight: normal;

            .level-item {
                padding-left: 0.4em;
            }
        }

        .tree-folder {
            & > .label {
                .level-left {
                    font-weight: bold;
                }

                .level-item {
                    padding-left: 0.3em;

                    span {
                        cursor: pointer;
                        padding-left: 0.2em;
                    }
                }
            }
        }

        .label {
            &:hover {
                background: #e2e2e2;

                .flags {
                    display: flex;
                }
            }

            .flags {
                display: none;
                color: $accentColor;
            }
        }

        .label.level {
            padding: 0.3em 0.3em 0.3em 0;
        }

        .handle {
            cursor: move;
        }
    }
</style>
