<template>
    <div>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <b-field>
                        <b-switch v-model="orderMode">Activar Reorden</b-switch>
                    </b-field>
                </div>
            </div>
            <div class="level-right">
                <div class="listing-header level-item">
                    <search-input :value="searchStr" @input="search($event)"></search-input>
                </div>
            </div>
        </div>
        <div class="tree-container">
            <tree-list
                :order-mode="orderMode"
                v-for="(child, index) in data"
                :key="index"
                :item="child">
            </tree-list>
        </div>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    import TreeList from "../fields/TreeList";
    import SearchInput from "../fields/SearchInput";

    export default {
        components: {TreeList, SearchInput},
        data: () => {
            return {
                data: [],
                searchStr: null,
                orderMode: false,
            }
        },
        mounted() {
            this.fetchData()
        },
        computed: {
            searchQuery() {
                return this.searchStr !== null && this.searchStr.length >= 3 ? this.searchStr : '';
            }
        },
        methods: {
            fetchData() {
                Local.get('/labels/listing', {
                    params: {
                        'searchQuery': this.searchQuery
                    }
                })
                .then((result) => {
                    this.data = result.data;
                });
            },
            onPageChange(page) {
                this.page = page;
                this.fetchData()
            },
            onSort(field, direction) {
                this.sortField = field;
                this.sortDir = direction;
                this.fetchData()
            },
            search(value) {
                this.searchStr = value
                this.fetchData()
            }
        }
    }

</script>

<style lang="scss">

</style>
