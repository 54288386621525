<template>
    <div>
        <div class="listing-header">
            <search-input :value="searchStr" @input="search($event)"></search-input>
        </div>
        <b-table
            :data="data"
            :loading="loading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortDirection"
            :default-sort="[sortField, sortDir]"
            @sort="onSort"
        >
            <template slot-scope="props">
                <b-table-column field="id" label="ID" sortable>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column field="first_name" label="First Name" sortable>
                    {{ props.row.first_name }}
                </b-table-column>
                <b-table-column field="last_name" label="Last Name" sortable>
                    {{ props.row.last_name}}
                </b-table-column>
                <b-table-column field="email" label="Email" sortable>
                    {{ props.row.email}}
                </b-table-column>

                <b-table-column label="Actions">
                    <crud-actions :id="props.row.id" resource="users" :title="props.row.title" @deleted="fetchData"
                                  v-if="!selector"/>
                    <b-button type="" pack="far" icon="check-circle" v-if="selector" @click="selectItem(props.row)">
                        Seleccionar
                    </b-button>
                </b-table-column>

            </template>
        </b-table>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    import SearchInput from "../fields/SearchInput";

    export default {
        name: "UsersListing",
        components: {SearchInput},
        data: () => {
            return {
                data: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortDir: 'desc',
                defaultSortDirection: 'desc',
                page: 0,
                perPage: 10,
                dateFormat: process.env.MIX_DBT_DATE_FORMAT,
                searchStr: null,
				selector: false
            }
        },
        created() {
            this.fetchData()
        },
        computed: {
            searchQuery() {
                return this.searchStr !== null && this.searchStr.length >= 3 ? this.searchStr : '';
            }
        },
        methods: {
            fetchData() {
                Local.get('/users/listing', {
                    params: {
                        'page': this.page,
                        'sortField': this.sortField,
                        'sortDir': this.sortDir,
                        'searchQuery': this.searchQuery
                    }
                })
                    .then((result) => {
                        console.log(result.data);
                        this.data = result.data.data;
                        this.total = result.data.total;
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.fetchData()
            },
            onSort(field, direction) {
                this.sortField = field;
                this.sortDir = direction;
                this.fetchData()
            },
            search(value) {
                this.searchStr = value
                this.fetchData()
            }
        }
    }

</script>

<style lang="scss">

</style>
