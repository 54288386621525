<template>
    <div :class="{ 'time-input': true, 'disabled': !editable }">
        <b-field grouped icon="clock">
            <p class="control">
                <b-icon icon="clock" size="is-medium"/>
            </p>
            <p class="control">
                <b-select
                    rounded
                    :value="minutes"
                    :disabled="!editable"
                    @input="updateValue($event, seconds)"
                    placeholder="00">
                    <option v-for="i in ii" :value="i" :key="i">
                        {{ i }}
                    </option>
                </b-select>
            </p>
            <p class="control">:</p>
            <p class="control">
                <b-select
                    rounded
                    :value="seconds"
                    :disabled="!editable"
                    @input="updateValue(minutes, $event)"
                    placeholder="00">
                    <option v-for="i in ii" :value="i" :key="i">
                        {{ i }}
                    </option>
                </b-select>
            </p>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: "TimeInput",
        props: {
            value: Number,
            editable: {type: Boolean, default: true},
        },
        data() {
            return {
                time: null,
                minutes: this.ii,
                seconds: this.ii
            }
        },
        created() {
            this.minutes = "00";
            this.seconds = "00";

            if (this.value != null) {
                let mmss = this.mmss(this.value)
                this.minutes = mmss.mm
                this.seconds = mmss.ss
            }
        },
        computed: {
            ii() {
                let ii = []
                for (var i = 0; i < 60; i++) {
                    ii.push(this.pad(i))
                }
                return ii
            }
        },
        methods: {
            updateValue(mm, ss) {
                this.minutes = mm
                this.seconds = ss
                this.$emit('input', this.ms(mm, ss));
            },
            ms(mm, ss) {
                let time = mm * 60
                time += parseInt(ss)
                console.log(mm, ss, time)
                return time * 1000
            },
            mmss(ms) {
                if (ms < 1000) {
                    return {mm: '00', ss: '00'}
                }

                let mm = Math.floor(ms / 60000)
                let ss = ((ms % 60000) / 1000).toFixed(0)
                return {mm: this.pad(mm), ss: this.pad(ss)}
            },
            pad(i) {
                return `0${i}`.substr(-2)
            }
        }
    }
</script>

<style scoped>

</style>
