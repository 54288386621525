<template>
    <div>
        <div class="listing-header">
            <search-input :value="searchStr" @input="search($event)"></search-input>
        </div>
        <b-table
            :data="data"
            :loading="loading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortDirection"
            :default-sort="[sortField, sortDir]"
            @sort="onSort"
        >
            <template slot-scope="props">
                <b-table-column field="id" label="ID" sortable>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column field="title" label="Titulo" sortable>
                    {{ props.row.title }}
                </b-table-column>
                <b-table-column field="duration" label="Duración" sortable>
                    {{ props.row.duration }}
                </b-table-column>
                <b-table-column field="skip_time" label="Posición" sortable>
                    <b-tag type="is-info">{{ props.row.position }}</b-tag>
                    {{ props.row.skip_time }}
                </b-table-column>
                <b-table-column field="publishing_date" label="Se publica el" sortable>
                    {{ props.row.start_at | moment(dateFormat) }}
                </b-table-column>
                <b-table-column field="expire_at" label="Hasta el" sortable>
                    {{ props.row.end_at | moment(dateFormat) }}
                </b-table-column>
                <b-table-column field="updated_at" label="Actualizado el" sortable>
                    {{ props.row.updated_at | moment(dateFormat) }}
                </b-table-column>
                <b-table-column label="Actions">
                    <crud-actions :id="props.row.id" resource="ads" :title="props.row.title" @deleted="fetchData"/>
                </b-table-column>

            </template>
        </b-table>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    import SearchInput from "../fields/SearchInput";

    export default {
        name: "AdsListing",
        components: {SearchInput},
        data: () => {
            return {
                data: [],
                total: 0,
                loading: false,
                sortField: 'created_at',
                sortDir: 'desc',
                defaultSortDirection: 'desc',
                page: 0,
                perPage: 10,
                dateFormat: process.env.MIX_DBT_DATE_FORMAT,
                searchStr: null,
            }
        },
        created() {
            this.fetchData()
        },
        computed: {
            searchQuery() {
                return this.searchStr !== null && this.searchStr.length >= 3 ? this.searchStr : '';
            }
        },
        methods: {
            async fetchData() {
                try {
                    const result = await Local.get('/ads/listing', {
                        params: {
                            'page': this.page,
                            'sortField': this.sortField,
                            'sortDir': this.sortDir,
                            'searchQuery': this.searchQuery
                        }
                    });
                    console.log(result.data);
                    this.data = result.data.data;
                    this.total = result.data.total;
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            },
            onPageChange(page) {
                this.page = page;
                this.fetchData()
            },
            onSort(field, direction) {
                this.sortField = field;
                this.sortDir = direction;
                this.fetchData()
            },
            search(value) {
                this.searchStr = value
                this.fetchData()
            }
        }
    }

</script>

<style lang="scss">

</style>
