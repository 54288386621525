var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text
    ? _c(
        "div",
        { staticClass: "error-message" },
        [
          _c(
            "b-message",
            {
              attrs: { type: "is-danger", "has-icon": "true", size: "is-small" }
            },
            [_vm._v("\n        " + _vm._s(_vm.text) + "\n    ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }