<template>
    <div>
        <div class="listing-header">
            <search-input :value="searchStr" @input="search($event)"></search-input>
        </div>
        <b-table
            :data="data"
            :loading="loading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortDirection"
            :default-sort="[sortField, sortDir]"
            @sort="onSort"
        >
            <template slot-scope="props">
                <b-table-column field="title" label="Titulo" sortable>
                    {{ props.row.title }}
                </b-table-column>
				<b-table-column field="publishing_date" label="Fecha de publicación" sortable>
					{{ props.row.publishing_date | moment('ddd DD/MM/YYYY HH:mm') }}
				</b-table-column>
				<b-table-column field="expiration_date" label="Fecha de expiración" sortable>
					<span v-if="props.row.expires">
						{{ props.row.expiration_date | moment('ddd DD/MM/YYYY HH:mm') }}
					</span>
					<span v-if="!props.row.expires">
						-
					</span>
				</b-table-column>
				<b-table-column field="on_air" label="On Air" sortable>
					<b-tag type="is-danger" v-if="props.row.on_air">Ocupado</b-tag>
				</b-table-column>

                <b-table-column field="url" label="Url" sortable>
                    <a :href="props.row.fullUrl" target="_blank">{{ props.row.url }}</a>
                </b-table-column>

				<b-table-column field="insertion_date" label="Fecha de inserción" sortable>
					{{ props.row.insertion_date | moment(dateFormat) }}
				</b-table-column>
				<b-table-column field="update_date" label="Fecha de actualización" sortable>
					{{ props.row.update_date | moment(dateFormat) }}
				</b-table-column>
                <b-table-column label="Acciones">
                    <crud-actions :id="props.row.id" resource="specials" :title="props.row.title" @deleted="fetchData"
                                  v-if="!selector"/>
                    <b-button type="" pack="far" icon="check-circle" v-if="selector && !multiple" @click="selectItem(props.row)">
                        Seleccionar
                    </b-button>
                    <b-checkbox v-model="itemsSelected" :native-value="props.row.id" @input="cbSelected" v-if="selector && multiple" />
                </b-table-column>

            </template>
        </b-table>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    import SearchInput from "../fields/SearchInput";

    export default {
        name: "SpecialListing",
        components: {SearchInput},
        props: {
            value: {type: Object, require: false, default: null},
            selector: {type: Boolean, require: false, default: false},
            multiple: {type: Boolean, require: false, default: false},
            values: {
                type: Array, require: false, default: () => {
                    return []
                }
            },
            cbSelected: {
                type: Function, require: false, default: () => {
                }
            },
            query: {type: Object, require: false, default: null},
        },
        data: () => {
            return {
                data: [],
                itemsSelected: [],
                total: 0,
                loading: false,
                sortField: 'publishing_date',
                sortDir: 'asc',
                defaultSortDirection: 'asc',
                page: 0,
                perPage: 10,
                dateFormat: process.env.MIX_DBT_DATE_FORMAT,
                searchStr: null,
            }
        },
        created() {
            if (this.selector && this.multiple) {
                this.itemsSelected = this.values
            }
            this.fetchData()
        },
        computed: {
            searchQuery() {
                return this.searchStr !== null && this.searchStr.length >= 3 ? this.searchStr : '';
            }
        },
        methods: {
            async fetchData() {
                try {
                    const result = await Local.get('/specials/listing', {
                        params: {
                            'page': this.page,
                            'sortField': this.sortField,
                            'sortDir': this.sortDir,
                            'searchQuery': this.searchQuery,
                            ...this.query
                        }
                    });
                    this.data = result.data.data;
                    this.total = result.data.total;
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            },
            onPageChange(page) {
                this.page = page;
                this.fetchData()
            },
            onSort(field, direction) {
                this.sortField = field;
                this.sortDir = direction;
                this.fetchData()
            },
            selectItem(value) {
                this.selectedRow = value
                this.$emit('change', value)
                this.cbSelected(value)
            },
            search(value) {
                this.searchStr = value
                this.fetchData()
            }
        }
    }

</script>

<style lang="scss">

</style>
