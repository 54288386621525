import Axios from 'axios';

Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

let Local = Axios.create({
    baseUrl: '/',
    timeout: 10000
});
	
let Api = Axios.create({
    baseUrl: '/api',
    timeout: 1000
});

// const Internal = Axios.create({
// 	baseURL: 'https://playlist-api.xcms.cross-media.es:3443',
// 	timeout: 10000
// });


export { Axios, Local, Api }
