var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    _c(
      "form",
      {
        attrs: { enctype: "multipart/form-data" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v("\n        Anadir un XML de programacion\n        "),
        _c("flash-box", {
          attrs: {
            message: {
              type: "is-info",
              text:
                "Selecciona un archivo XML correspondiente a la programacion de un dia que quieras anadir."
            }
          }
        }),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Tipo de programacion" } },
          [
            _c(
              "b-radio",
              {
                attrs: { name: "type", "native-value": "tv", required: "" },
                model: {
                  value: _vm.form.type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type"
                }
              },
              [_vm._v("\n                Tv\n            ")]
            ),
            _vm._v(" "),
            _c(
              "b-radio",
              {
                attrs: { name: "type", "native-value": "radio" },
                model: {
                  value: _vm.form.type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type"
                }
              },
              [_vm._v("\n                Radio\n            ")]
            ),
            _vm._v(" "),
            _c(
              "b-radio",
              {
                attrs: { name: "type", "native-value": "internet" },
                model: {
                  value: _vm.form.type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type"
                }
              },
              [_vm._v("\n                Internet\n            ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Archivo XML" } },
          [
            _c(
              "b-upload",
              {
                attrs: { required: "", accept: ".xml" },
                model: {
                  value: _vm.form.file,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "file", $$v)
                  },
                  expression: "form.file"
                }
              },
              [
                _c("a", { staticClass: "button is-primary" }, [
                  _c("span", [_vm._v("Examinar")])
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.form.file && _vm.form.file.name
          ? _c("div", { staticClass: "tags" }, [
              _c("span", { staticClass: "tag is-primary" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.form.file.name) +
                    "\n                "
                ),
                _c("button", {
                  staticClass: "delete is-small",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.deleteFile()
                    }
                  }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "is-success",
                        "native-type": "button",
                        disabled:
                          _vm.submitting ||
                          !(_vm.form.file && _vm.form.file.name)
                      },
                      on: { click: _vm.submit }
                    },
                    [
                      _vm._v(
                        "\n                            Guardar\n                        "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _vm._v(
          "\n                        * Campos Obligatorios\n                    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-item" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }