var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color_input" },
    [
      _c(
        "b-field",
        [
          _c("b-input", {
            attrs: {
              type: "text",
              name: _vm.name,
              value: _vm.colorValue,
              maxlength: "7"
            },
            on: {
              input: function($event) {
                return _vm.updateColor($event)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "control" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button is-primary",
                  on: {
                    click: function($event) {
                      _vm.showColorPicker = !_vm.showColorPicker
                    }
                  }
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", { staticClass: "fas fa-eye-dropper" })
                  ]),
                  _vm._v("\n                 \n                "),
                  !_vm.showColorPicker
                    ? _c("span", [_vm._v("Elige un color...")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showColorPicker
                    ? _c("span", [_vm._v("Cerrar picker")])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showColorPicker
        ? _c("color-picker", {
            attrs: { value: _vm.colorValue || "#ffffff" },
            on: {
              input: function($event) {
                return _vm.updateColor($event.hex || 0)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }