var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "top-bar" }, [
    _c("nav", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Redacción À Punt / " + _vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c("nav", { staticClass: "breadcrumbs" }, [_vm._t("breadcrumbs")], 2)
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level-right username" },
        [
          _c("i", { staticClass: "fal fa-user" }),
          _vm._v(" "),
          _vm._t("username"),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "logout-form",
              attrs: { action: _vm.logoutUrl, method: "POST" }
            },
            [
              _c("button", {
                staticClass: "fal fa-power-off",
                attrs: { type: "submit" }
              })
            ]
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }