var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-input" },
    [
      _c(
        "b-field",
        [
          _c("b-input", {
            attrs: {
              rounded: "",
              value: _vm.searchQuery,
              placeholder: _vm.placeholder,
              icon: _vm.icon,
              "icon-right": _vm.clearIcon,
              "icon-right-clickable": true,
              clearable: ""
            },
            on: {
              input: function($event) {
                return _vm.updateValue($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }