<template>
    <div class="form news-form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>
            <div class="columns">
                <b-field
                    class="column"
                    label="Mostrar Foto/Video"
                    :type="errors.type('news_show_picture_only')"
                    :message="errors.get('news_show_picture_only')">
                    <b-select v-model="form.news_show_picture_only" name="news_show_picture_only">
                        <option value="0">Video</option>
                        <option value="1">Foto</option>
                    </b-select>
                </b-field>

                <b-field class="column"
                         label="Id del video"
                         :type="errors.type('news_video_asset')"
                         :message="errors.get('news_video_asset')">
                    <video-id-selector
                        id="news_video_asset"
                        name="news_video_asset"
                        data-url="/news/listing/noticia,video"
                        v-model="form.news_video_asset"
                    />
                </b-field>
            </div>
            <b-message>
                Selecciona una opcion como preferente para mostrar en la cabecera si existe tanto video como thumbnail.
            </b-message>

            <b-field label="Thumbnail"
                     style=""
                     type="is-danger"
                     :type="errors.type('thumbnail')"
                     :message="errors.get('thumbnail')">
                <image-upload-box
                    ref="thumbnail"
                    :default-url="form.thumbnail_url"
                    v-model="thumbData"/>
            </b-field>

            <b-message>
                Escoja thumbnail o video asociado, pero no ambos. En caso de que exista un thumbnail y un video puede
                seleccionar abajo que elemento aparecera en la cabecera. Puede insertar mas imagenes en el cuerpo de la
                noticia.
                Tamano maximo de archivo 1Mb. Resolucion maxima recomandada 1280x720 con una relacion de aspecto 16:9
            </b-message>

            <b-field label="* Titulo"
                     :type="errors.type('title')"
                     :message="errors.get('title')">
                <b-input
                    name="title"
                    type="text"
                    v-model="form.title"
                    @change="bindCleanTitle(form.title)"
                    @input="bindCleanTitle(form.title)"
                    expanded
                />
            </b-field>

            <b-field label="Url">
                <slug-input ref="slug"
                            v-model="form.clean_title"
                            prefix="https://apuntmedia.es/../"
                            :type="errors.type('clean_title')"
                            :message="errors.get('clean_title')"
                            :synced-on-start="syncTitleWithSlug"
                            name="clean_title"></slug-input>
            </b-field>

            <b-field
                label="Descripcion"
                :type="errors.type('description')"
                :message="errors.get('description')">
                <summernote class="form-control"
                            name="editor"
                            v-model="form.description"
                ></summernote>
            </b-field>

            <b-field
                label="Descripcion corta"
                :type="errors.type('short_description')"
                :message="errors.get('short_description')">
                <b-input type="textarea" v-model="form.short_description" name="short_description"/>
            </b-field>

            <b-field
                label="Alt"
                :type="errors.type('alt_thumbnail')"
                :message="errors.get('alt_thumbnail')">
                <b-input type="text" v-model="form.alt_thumbnail" name="alt_thumbnail"/>
            </b-field>

            <b-field
                label="Pie de Foto"
                :type="errors.type('thumb_description')"
                :message="errors.get('thumb_description')">
                <b-input type="textarea" v-model="form.thumb_description" name="thumb_description"/>
            </b-field>

            <b-message>Indica los autores (Si hubiera). El campo URL es para el perfill de twitter del mismo, y debe de
                ser una URL bien formada o sino, no te permitira guardar. un ejemplo es:
                http://www.twitter.com/apunt_media
            </b-message>

            <div class="authors">
                <div class="columns">
                    <div class="field column is-1">
                        <div class="label">#</div>
                    </div>
                    <div class="field column">
                        <div class="label">Autor</div>
                    </div>
                    <div class="field column">
                        <div class="label">URL</div>
                    </div>
                </div>
                <div class="columns" v-for="(author, index) in form.authors.slice(0, 3)">
                    <div class="field column is-1">
                        <div class="label">{{ index + 1 }}</div>
                    </div>
                    <b-field class="column"
                             :message="errors.get('author.name')">
                        <b-input type="text" v-model="form.authors[index].name"/>
                    </b-field>
                    <b-field class="column"
                             :message="errors.get('author.url')">
                        <b-input type="text" v-model="form.authors[index].url"/>
                    </b-field>
                </div>
            </div>

            <b-message>
                El idioma es obligatorio y necesario para podes filtrar los video por idioma.
            </b-message>

            <b-field label="* Idioma"
                     :type="errors.type('language')"
                     :message="errors.get('language')">
                <b-select v-model="form.language" name="language">
                    <option value="ES">Espanol</option>
                    <option value="CA">Valenciano</option>
                    <option value="EN">Ingles</option>
                </b-select>
            </b-field>

            <b-message>
                <strong>Importante: </strong>Los assets no se publicaran en la platforma si su fecha de activacion esta
                en el futuro o si la fecha de desactivation ha expirado
            </b-message>

            <b-field label="Fecha de publicacion"
                     :type="errors.type('publishing_date')"
                     :message="errors.get('publishing_date')">
                <calendar-input
                    v-model="form.publishing_date">
                </calendar-input>
            </b-field>

            <b-field label="Fecha de finalizacion"
                     :type="errors.type('expiration_date')"
                     :message="errors.get('expiration_date')">
                <calendar-input
                    v-model="form.expiration_date"></calendar-input>
            </b-field>

            <b-message>
                Lista de palabras clave separadas por comas
            </b-message>

            <b-field label="Keywords"
                     :type="errors.type('keywords')"
                     :message="errors.get('keywords')">
                <b-taginput
                    v-model="form.keywords"
                    ellipsis
                    icon="label"
                    placeholder="Agregar keyword">
                </b-taginput>
            </b-field>

            <b-field label="Comarcas"
                     :type="errors.type('counties')"
                     :message="errors.get('counties')">
                <multi-select
                    :multiple="true"
                    v-model="form.counties"
                    data-url="/regions/listing">
                </multi-select>
            </b-field>

            <b-field label="Labels"
                     :type="errors.type('labels')"
                     :message="errors.get('labels')">
                <multi-select
                    :multiple="true"
                    v-model="form.labels"
                    data-url="/labels/listing"
                />
            </b-field>

            <b-field label="Player ID"
                     :type="errors.type('player_bc')"
                     :message="errors.get('player_bc')">
                <async-select
                    :multiple="true"
                    v-model="form.player_bc"
                    data-url="/bcapi/players-list"
                />
            </b-field>

            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button
                                tag="a" href="/news">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                class="is-success"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import MultiSelect from "../fields/MultiSelect";
    import ImageUploadBox from "../fields/ImageUploadBox"
    import moment from 'moment';
    import SlugInput from "../fields/SlugInput";
    import AsyncSelect from "../fields/AsyncSelect";
    import VideoIdSelector from "../fields/VideoIdSelector";
    import Summernote from '../../lib/Summernote';
    import CalendarInput from "../fields/CalendarInput";

    export default {
        name: "NewsForm",
        components: {CalendarInput, VideoIdSelector, AsyncSelect, SlugInput, ImageUploadBox, MultiSelect, Summernote},
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                thumbData: null,
                id: null,
                syncTitleWithSlug: true,
                minDatetime: moment().subtract(10, 'years').toDate(),
                maxDatetime: moment().add(50, 'years').toDate(),
                form: {
                    thumbnail_url: null,
                    news_video_asset: null,
                    title: null,
                    clean_title: null,
                    description: null,
                    short_description: null,
                    alt_thumbnail: null,
                    thumb_description: null,
                    news_show_picture_only: 1,
                    authors: [{}, {}, {}],
                    counties: [],
                    labels: [],
                    language: 'CA',
                    publishing_date: new Date(),
                    expiration_date: moment().add(1, 'years').toDate(),
                    keywords: null,
                    player_bc: 'default'
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values && this.values.id) {
                this.id = this.values.id;
                this.form = {...this.form, ...this.values}
                this.form.publishing_date = new Date(this.form.publishing_date)
                this.form.expiration_date = new Date(this.form.expiration_date)
                this.form.player_bc = this.form.player_bc || 'default';
            }

            this.syncTitleWithSlug = !this.isEditing
        },
        computed: {
            isEditing() {
                return this.id !== null
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();

                    if (this.thumbData) {
                        formData.append('thumbnail', this.thumbData);
                    }

                    formData.append('news_show_picture_only', this.form.news_show_picture_only || '0');
                    formData.append('news_video_asset', this.form.news_video_asset || '');
                    formData.append('title', this.form.title || '');
                    formData.append('clean_title', this.form.clean_title || '');
                    formData.append('description', this.form.description || "");
                    formData.append('short_description', this.form.short_description || "");
                    formData.append('alt_thumbnail', this.form.alt_thumbnail || "");
                    formData.append('thumb_description', this.form.thumb_description || "");
                    formData.append('authors', JSON.stringify(this.form.authors));
                    formData.append('language', this.form.language || '');
                    formData.append('publishing_date', moment(this.form.publishing_date).format());
                    formData.append('expiration_date', moment(this.form.expiration_date).format());
                    formData.append('keywords', this.form.keywords || '');
                    formData.append('counties', this.form.counties || []);
                    formData.append('labels', this.form.labels || []);
                    formData.append('player_bc', this.form.player_bc || '');

                    let url = `/news/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    this.errors.reset();
                    this.$buefy.toast.open({
                        message: 'La noticia ha sido guardada',
                        type: 'is-success'
                    });

                    const values = result.data.values
                    if (!this.form.id && result.data.id) {
                        setTimeout(() => {
                            let toks = window.location.href.split('/');
                            toks.push(result.data.id);
                            window.location.href = toks.join('/');
                        }, 2000);
                    } else if (values && values.thumbnail_url) {
                        this.$refs.thumbnail.reset(values.thumbnail_url);
                    }
                    this.submitting = false;
                } catch (error) {
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Hay errores en el formulario',
                        type: 'is-danger'
                    });
                }
            },
            bindCleanTitle(value) {
                this.$refs.slug.syncSlug(value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        #news_video_asset {
            width: 13em;
        }
    }

    .button.is-primary {
        background-color: #72BECC;
        border-color: transparent;
        color: white;
    }

    .select:not(.is-multiple):not(.is-loading)::after {
        border-color: #72BECC;
        right: 1.125em;
        z-index: 4;
    }
</style>
