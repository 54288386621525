var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("b-message", { staticClass: "is-dark" }, [
        _vm._v("Completar y activar")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-wrapper" },
        [
          _c("h2", [_vm._v("Home")]),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "URL" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.data[1].url,
                  callback: function($$v) {
                    _vm.$set(_vm.data[1], "url", $$v)
                  },
                  expression: "data[1].url"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Texto" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.data[1].title,
                  callback: function($$v) {
                    _vm.$set(_vm.data[1], "title", $$v)
                  },
                  expression: "data[1].title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("footer", { staticClass: "level" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _c(
                  "b-checkbox",
                  {
                    model: {
                      value: _vm.data[1].checkbox,
                      callback: function($$v) {
                        _vm.$set(_vm.data[1], "checkbox", $$v)
                      },
                      expression: "data[1].checkbox"
                    }
                  },
                  [_vm._v("\n                    Activo\n                ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "level-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-success" },
                    on: {
                      click: function($event) {
                        return _vm.saveData(1)
                      }
                    }
                  },
                  [_vm._v("Guardar")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-wrapper" },
        [
          _c("h2", [_vm._v("Notícias")]),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "URL" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.data[2].url,
                  callback: function($$v) {
                    _vm.$set(_vm.data[2], "url", $$v)
                  },
                  expression: "data[2].url"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Texto" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.data[2].title,
                  callback: function($$v) {
                    _vm.$set(_vm.data[2], "title", $$v)
                  },
                  expression: "data[2].title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("footer", { staticClass: "level" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _c(
                  "b-checkbox",
                  {
                    model: {
                      value: _vm.data[2].checkbox,
                      callback: function($$v) {
                        _vm.$set(_vm.data[2], "checkbox", $$v)
                      },
                      expression: "data[2].checkbox"
                    }
                  },
                  [_vm._v("\n                    Activo\n                ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "level-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-success" },
                    on: {
                      click: function($event) {
                        return _vm.saveData(2)
                      }
                    }
                  },
                  [_vm._v("Guardar")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }