<template>
    <div>
        <b-tooltip position="is-right"
                   type="is-light"
                   multilined
                   label="message">
            {{ label }}
            <span class="icon"><i class="far fa-question-circle"></i></span>
        </b-tooltip>
    </div>
</template>

<script>
    export default {
        props: {
            label: String,
            message: String
        },
        name: "HelpHint",
        created() {
            console.log(this)
        }
    }
</script>

<style scoped>

</style>
