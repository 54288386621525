var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thumb-box item-selector" },
    [
      _vm.itemSelected
        ? _c("article", [
            _c("div", [
              _vm.previewImageUrl
                ? _c("img", {
                    staticClass: "preview",
                    attrs: { src: _vm.previewImageUrl }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.itemSelected && _vm.itemSelected.title
              ? _c("footer", { staticClass: "tags" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.itemSelected.title) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "footer",
        [
          _c(
            "b-button",
            {
              staticClass: "button is-primary",
              on: {
                click: function($event) {
                  _vm.isSelecting = true
                }
              }
            },
            [_c("span", [_vm._v("Seleccionar...")])]
          ),
          _vm._v(" "),
          _vm.canRemove
            ? _c(
                "b-button",
                {
                  staticClass: "button is-danger",
                  on: {
                    click: function($event) {
                      return _vm.updateValue({})
                    }
                  }
                },
                [_c("span", [_vm._v("Borrar")])]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isSelecting,
            "full-screen": "",
            "has-modal-card": "",
            "can-cancel": true
          },
          on: {
            "update:active": function($event) {
              _vm.isSelecting = $event
            }
          }
        },
        [
          _c("modal-form", {
            attrs: { value: _vm.itemSelected, "data-url": _vm.dataUrl },
            on: {
              input: function($event) {
                return _vm.updateValue($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }