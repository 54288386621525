<template>
    <div class="form users-form">
        <form @submit.prevent="nosubmit" autocomplete="off">
            <slot name="csrf"/>

            <b-field
                :message="errors.get('first_name')"
                label="Nombre">
                <b-input
                    type="text"
                    name="first_name"
                    v-model="form.first_name">
                </b-input>
            </b-field>

            <b-field
                :message="errors.get('last_name')"
                label="Apellidos">
                <b-input
                    type="text"
                    name="last_name"
                    v-model="form.last_name">
                </b-input>
            </b-field>

            <b-field
                :message="errors.get('email')"
                label="Email">
                <b-input
                    type="email"
                    name="email"
                    v-model="form.email"
                    maxlength="50">
                </b-input>
            </b-field>

            <b-field
                label="Password">
                <b-input
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    v-model="form.password"
                    password-reveal>
                </b-input>
            </b-field>

            <div class="field">
                <label class="label">* Permisos</label>
                <div>
                    <b-field v-for="permission in permissions" :key="permission.id">
                        <b-checkbox
                            :native-value="permission.id"
                            v-model="form.permissions">
                            {{permission.description}}
                        </b-checkbox>
                    </b-field>
                </div>
            </div>
            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button
                                tag="a" href="/news">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                               class="is-success"
                               native-type="button"
                               @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>

        </form>

    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import moment from "moment";
    import {Local} from "../../lib/Request";

    export default {
        name: "UsersForm",
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                id: null,
                form: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null,
                    permissions: {}
                },
                permissions: {},
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values !== null) {
                this.id = this.values.user.id;
                this.form = this.values.user;
                this.permissions = this.values.permissions;
                this.form.permissions = this.values.user.permissions;
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();

                    formData.append('first_name', this.form.first_name);
                    formData.append('last_name', this.form.last_name);
                    formData.append('email', this.form.email);
                    formData.append('password', this.form.password);
                    formData.append('permissions', JSON.stringify(this.form.permissions));
                    let url = `/users/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    if (!(result.data && result.data.id)) {
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'El usuario ha sido guardado',
                        type: 'is-success'
                    });

                    this.errors.reset();
                    if (!this.form.id && result.data.id) {
                        setTimeout(() => {
                            let toks = window.location.href.split('/');
                            toks.push(result.data.id);
                            window.location.href = toks.join('/');
                        }, 2000);
                    } else {
                        this.form = {...this.form, ...result.data.values}
                        this.thumbData = null;
                    }
                    this.submitting = false;
                } catch (error) {
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Something went wrong',
                        type: 'is-danger'
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
</style>
