<template>
    <div class="login-box">
        <form method="POST" :action="action">
            <slot name="csrf"/>
            <b-field
                :type="errors.type('email')"
                :message="errors.get('email')">
                <b-input type="email" name="email"
                         value=""
                         required placeholder="email"
                         autocomplete="email" autofocus/>
            </b-field>

            <b-field
                :type="errors.type('password')"
                :message="errors.get('password')">
                <b-input id="password"
                         type="password" name="password"
                         required autocomplete="current-password"/>
            </b-field>

            <b-field grouped position="">
                <b-button type="is-fullwidth is-primary" native-type="submit">
                    Entrar
                </b-button>
            </b-field>
        </form>
    </div>
</template>

<script>
    import Errors from "../../lib/Errors";

    export default {
        name: "LoginBox",
        props: {
            action: {required: false, default: null},
            errorMessages: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors()
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);
        }
    }
</script>


