var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "listing-header" },
        [
          _c("search-input", {
            attrs: {
              placeholder: "Buscar por label",
              value: _vm.labelStr,
              icon: "tag"
            },
            on: {
              input: function($event) {
                return _vm.search(_vm.searchStr, $event)
              }
            }
          }),
          _vm._v(" "),
          _c("search-input", {
            attrs: { value: _vm.searchStr },
            on: {
              input: function($event) {
                return _vm.search($event, _vm.labelStr)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          data: _vm.data,
          loading: _vm.loading,
          paginated: "",
          "backend-pagination": "",
          total: _vm.total,
          "per-page": _vm.perPage,
          "backend-sorting": "",
          "default-sort-direction": _vm.defaultSortDirection,
          "default-sort": [_vm.sortField, _vm.sortDir]
        },
        on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "id", label: "ID", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.id) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "title", label: "Titulo", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.title) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "bc_id",
                      label: "Brightcove ID",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.bc_id) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "publishing_date",
                      label: "Publicado el",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.publishing_date,
                            _vm.dateFormat
                          )
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "insertion_date",
                      label: "Agregado el",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.insertion_date,
                            _vm.dateFormat
                          )
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "update_date",
                      label: "Actualizado el",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("moment")(
                            props.row.update_date,
                            _vm.dateFormat
                          )
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Actions" } },
                  [
                    _c("crud-actions", {
                      attrs: {
                        id: props.row.id,
                        resource: "media",
                        title: props.row.title
                      },
                      on: { deleted: _vm.fetchData }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }