var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card reorder-modal" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "header",
      { staticClass: "modal-card-head toolbar is-clearfix" },
      [
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              { attrs: { horizontal: "", label: "Ordenar" } },
              [
                _c(
                  "b-select",
                  {
                    nativeOn: {
                      change: function($event) {
                        return _vm.doReorder($event.target.value, "mode")
                      }
                    },
                    model: {
                      value: _vm.mode,
                      callback: function($$v) {
                        _vm.mode = $$v
                      },
                      expression: "mode"
                    }
                  },
                  [
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("Por defecto (manual)")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("Por Fecha (auto)")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("Por Titulo (auto)")
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("b-field", { staticClass: "gap has-text-centered" }, [
              _vm._v("|")
            ]),
            _vm._v(" "),
            _c("b-field", [
              _c(
                "button",
                {
                  staticClass: "button is-success",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.doReorder(null, "update")
                    }
                  }
                },
                [
                  _vm._v(
                    "Guardar orden\n                    manual\n                "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("b-field", [
              _c(
                "button",
                {
                  staticClass: "button is-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.doReorder(null, "remove")
                    }
                  }
                },
                [_vm._v("Remover orden\n                ")]
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("b-loading", {
          attrs: { "is-full-page": true, active: _vm.isLoading },
          on: {
            "update:active": function($event) {
              _vm.isLoading = $event
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "modal-card-body content" },
      [
        _c("b-message", [
          _vm._v(
            '\n            - Para activar el reorden manual, elije "Ordenar: Por defecto"'
          ),
          _c("br"),
          _vm._v(
            '\n            - Recuerda presionar "Guardar orden manual" despues de reordenar los items'
          )
        ]),
        _vm._v(" "),
        _c(
          "draggable",
          {
            attrs: { list: _vm.treeData, disabled: _vm.disabled },
            on: {
              end: function($event) {
                return _vm.doReorder()
              }
            }
          },
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item level box",
                attrs: { item: item }
              },
              [
                _c("div", { staticClass: "level-left" }, [
                  _c("div", { staticClass: "level-item" }, [
                    _c(
                      "p",
                      {
                        staticClass: "title is-5",
                        staticStyle: { color: "#999" }
                      },
                      [_vm._v("#" + _vm._s(index + 1))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "level-item item" }, [
                    _c("p", { staticClass: "title is-6" }, [
                      _vm._v("(" + _vm._s(item.id) + ") " + _vm._s(item.title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "level-item has-text-centered" },
                    [
                      _c("b-tag", { attrs: { type: "is-info" } }, [
                        _vm._v(_vm._s(item.type))
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "level-right" }, [
                  !_vm.disabled
                    ? _c(
                        "span",
                        { staticClass: "level-item icon handle is-6" },
                        [_c("i", { staticClass: "fa fa-align-justify" })]
                      )
                    : _vm._e()
                ])
              ]
            )
          }),
          0
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("footer", { staticClass: "modal-card-foot" }, [
      _c(
        "button",
        {
          staticClass: "button pull-right",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$parent.close()
            }
          }
        },
        [_vm._v("Cerrar")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head is-clearfix" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("Reorganizar Items")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }