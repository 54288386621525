<template>
    <div class="form covers-form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>

            <div class="box">
            <b-field
                label="Carrusel">
                <b-select v-model="form.elements[0].value" name="item_1">
                    <option value="1">Web</option>
                    <option value="2">Movíl</option>
                </b-select>
            </b-field>
            </div>

            <div class="box">
            <b-field label="Título"
                     :message="errors.get('element.1')">
                <b-input
                    name="title"
                    type="text"
                    v-model="form.elements[1].value"
                    expanded
                />
            </b-field>

            <b-field label="Més"
                     :message="errors.get('labels')">
                <multi-select
                    :multiple="false"
                    :flat="false"
                    v-model="form.elements[2].value"
                    data-url="/labels/listing"
                />
            </b-field>

            <div class="columns">
                <div class="column has-text-centered">
                    <b-field>
                        <item-selector v-model="form.elements[3].value"
                                       :default-value="form.elements[3].data"
                                       data-url="/news/listing/all" />
                    </b-field>
                </div>
                <div class="column has-text-centered">
                    <b-field>
                        <item-selector v-model="form.elements[4].value"
                                       :default-value="form.elements[4].data"
                                       data-url="/news/listing/all" />
                    </b-field>
                </div>
            </div>
            </div>

            <div class="box">
                <b-field label="Título"
                         :message="errors.get('element.1')">
                    <b-input
                        name="title"
                        type="text"
                        v-model="form.elements[5].value"
                        expanded
                    />
                </b-field>

                <b-field label="Més"
                         :message="errors.get('labels')">
                    <multi-select
                        :multiple="false"
                        :flat="false"
                        v-model="form.elements[6].value"
                        data-url="/labels/listing"
                    />
                </b-field>

                <div class="columns">
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[7].value"
                                           :default-value="form.elements[7].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[8].value"
                                           :default-value="form.elements[8].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[9].value"
                                           :default-value="form.elements[9].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>

                </div>
            </div>

            <div class="box">
                <b-field label="Título"
                         :message="errors.get('element.1')">
                    <b-input
                        name="title"
                        type="text"
                        v-model="form.elements[10].value"
                        expanded
                    />
                </b-field>

                <b-field label="Més"
                         :message="errors.get('labels')">
                    <multi-select
                        :multiple="false"
                        :flat="false"
                        v-model="form.elements[11].value"
                        data-url="/labels/listing"
                    />
                </b-field>

                <div class="columns">
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[12].value"
                                           :default-value="form.elements[12].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[13].value"
                                           :default-value="form.elements[13].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[14].value"
                                           :default-value="form.elements[14].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[15].value"
                                           :default-value="form.elements[15].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>

                </div>
            </div>

            <div class="box">
                <b-field label="Título"
                         :message="errors.get('element.1')">
                    <b-input
                        name="title"
                        type="text"
                        v-model="form.elements[16].value"
                        expanded
                    />
                </b-field>

                <b-field label="Més"
                         :message="errors.get('labels')">
                    <multi-select
                        :multiple="false"
                        :flat="false"
                        v-model="form.elements[17].value"
                        data-url="/labels/listing"
                    />
                </b-field>

                <div class="columns">
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[18].value"
                                           :default-value="form.elements[18].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[19].value"
                                           :default-value="form.elements[19].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[20].value"
                                           :default-value="form.elements[20].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>
                    <div class="column has-text-centered">
                        <b-field>
                            <item-selector v-model="form.elements[21].value"
                                           :default-value="form.elements[21].data"
                                           data-url="/news/listing/all" />
                        </b-field>
                    </div>

                </div>
            </div>

<!--            <b-carousel :indicator-inside="false">-->
<!--                <b-carousel-item v-for="(item, i) in 6" :key="i">-->
<!--            <span class="image">-->
<!--              <img :src="getImgUrl(i)">-->
<!--            </span>-->
<!--                </b-carousel-item>-->
<!--                <template slot="indicators" slot-scope="props">-->
<!--            <span class="al image">-->
<!--                <img :src="getImgUrl(props.i)" :title="props.i">-->
<!--            </span>-->
<!--                </template>-->
<!--            </b-carousel>-->


            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button
                                tag="a" href="/news">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import MultiSelect from "../fields/MultiSelect"
    import ItemSelector from "../fields/ItemSelector"
    import moment from 'moment';
    import CoverValue from "../../lib/CoverValue";

    export default {
        name: "CoverWebForm",
        components: {ItemSelector, MultiSelect},
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                id: null,
                form: {
                    elements: [],
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values && this.values.id) {
                this.id = this.values.id;

                this.form.elements = this.values.elements.map((e) => {
                    return new CoverValue(e)
                })
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData();
                    let elements = this.form.elements.map((e) => {
                        return e.out()
                    })
                    formData.append('elements', JSON.stringify(elements));

                    let url = `/covers/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    if (!(result.data && result.data.id)) {
                        throw new Error('Backend error');
                    }
                    this.$buefy.toast.open({
                        message: 'La portada ha sido actualizada',
                        type: 'is-success'
                    });

                    this.errors.reset();
                    this.submitting = false;
                } catch (error) {
                    console.log(error)
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Something went wrong',
                        type: 'is-danger'
                    });
                }
            }
        }
    }

</script>
