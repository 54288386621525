var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "time-input": true, disabled: !_vm.editable } },
    [
      _c("b-field", { attrs: { grouped: "", icon: "clock" } }, [
        _c(
          "p",
          { staticClass: "control" },
          [_c("b-icon", { attrs: { icon: "clock", size: "is-medium" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "control" },
          [
            _c(
              "b-select",
              {
                attrs: {
                  rounded: "",
                  value: _vm.minutes,
                  disabled: !_vm.editable,
                  placeholder: "00"
                },
                on: {
                  input: function($event) {
                    return _vm.updateValue($event, _vm.seconds)
                  }
                }
              },
              _vm._l(_vm.ii, function(i) {
                return _c("option", { key: i, domProps: { value: i } }, [
                  _vm._v(
                    "\n                    " + _vm._s(i) + "\n                "
                  )
                ])
              }),
              0
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("p", { staticClass: "control" }, [_vm._v(":")]),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "control" },
          [
            _c(
              "b-select",
              {
                attrs: {
                  rounded: "",
                  value: _vm.seconds,
                  disabled: !_vm.editable,
                  placeholder: "00"
                },
                on: {
                  input: function($event) {
                    return _vm.updateValue(_vm.minutes, $event)
                  }
                }
              },
              _vm._l(_vm.ii, function(i) {
                return _c("option", { key: i, domProps: { value: i } }, [
                  _vm._v(
                    "\n                    " + _vm._s(i) + "\n                "
                  )
                ])
              }),
              0
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }