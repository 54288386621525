var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thumb-box" },
    [
      _c(
        "b-upload",
        {
          ref: "upload",
          attrs: { accept: _vm.accept, multiple: false },
          on: {
            input: function($event) {
              return _vm.updateValue($event)
            }
          }
        },
        [
          _c("a", { staticClass: "button is-primary" }, [
            _c("span", [_vm._v("Examinar...")])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _vm.previewImageUrl
          ? _c("img", {
              staticClass: "preview",
              attrs: { src: _vm.previewImageUrl }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.image && _vm.image.name
        ? _c("footer", { staticClass: "tags" }, [
            _c(
              "div",
              { staticClass: "tag is-primary" },
              [
                _c("b-icon", { attrs: { icon: "upload" } }),
                _vm._v(
                  "\n            " + _vm._s(_vm.image.name) + "\n            "
                ),
                _c("button", {
                  staticClass: "delete is-small",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.deleteThumbnail()
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }