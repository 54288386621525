/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import 'summernote/dist/summernote-lite';
import 'summernote/dist/summernote-lite.css';
import wysiwyg from "vue-wysiwyg";
// import {Internal} from './lib/Request';

/*
Internal.get('/api/playlist')
.then((result) => {
	console.log(result);
	Internal.post('/api/playlist')
		.then((result) => {
			console.log('post', result);
		})
});
*/

import moment from 'moment';
require('moment/locale/es');

Vue.use(require('vue-moment'), {
	moment
});
Vue.use(Buefy, {
	defaultIconPack: 'mdi',
	defaultContainerElement: '#root'
});
Vue.use(wysiwyg, {});

Vue.component('flash-box', require('./components/theme/FlashBox.vue').default);
Vue.component('crud-actions', require('./components/fields/CrudActions.vue').default);
Vue.component('app-content', require('./components/theme/AppContent.vue').default);
Vue.component('app-top-bar', require('./components/theme/AppTopBar.vue').default);
Vue.component('bc-link', require('./components/theme/BreadcrumbItem.vue').default);
Vue.component('error-message', require('./components/fields/ErrorMessage.vue').default);
Vue.component('login-box', require('./components/forms/LoginBox.vue').default);
Vue.component('main-nav', require('./components/theme/MainNav.vue').default);
Vue.component('menu-item', require('./components/theme/MenuItem.vue').default);
Vue.component('help-hint', require('./components/fields/HelpHint.vue').default);

Vue.component('breaking-news', require('./components/forms/BreakingNewsForm.vue').default);

Vue.component('media-listing', require('./components/tables/MediaListing.vue').default);
Vue.component('media-form', require('./components/forms/MediaForm.vue').default);

Vue.component('news-listing', require('./components/tables/NewsListing.vue').default);
Vue.component('news-form', require('./components/forms/NewsForm.vue').default);

Vue.component('labels-listing', require('./components/tables/LabelsListing.vue').default);
Vue.component('labels-form', require('./components/forms/LabelsForm.vue').default);

Vue.component('ads-listing', require('./components/tables/AdsListing.vue').default);
Vue.component('ads-form', require('./components/forms/AdsForm.vue').default);

Vue.component('users-listing', require('./components/tables/UsersListing.vue').default);
Vue.component('users-form', require('./components/forms/UsersForm.vue').default);

Vue.component('carousel-listing', require('./components/tables/CarouselListing.vue').default);
Vue.component('carousel-form', require('./components/forms/CarouselForm.vue').default);
Vue.component('cover-web-form', require('./components/forms/CoverWebForm.vue').default);
Vue.component('cover-tv-form', require('./components/forms/CoverTvForm.vue').default);
Vue.component('cover-web-news-form', require('./components/forms/CoverWebNewsForm.vue').default);

Vue.component('specials-listing', require('./components/tables/SpecialsListing.vue').default);
Vue.component('specials-form', require('./components/forms/SpecialsForm.vue').default);
Vue.component('programming-listing', require('./components/tables/ProgrammingListing.vue').default);
Vue.component('programming-form', require('./components/forms/ProgrammingForm.vue').default);

Vue.component('shorturls-listing', require('./components/tables/ShortUrlListing.vue').default);
Vue.component('shorturls-form', require('./components/forms/ShortUrlForm.vue').default);

Vue.component('color-input', require('./components/fields/ColorInput.vue').default);

const app = new Vue({
	el: '#root',
	data() {
		return {
			isActive: true
		}
	}
});

