<template>
    <header class="top-bar">
        <nav class="level">
            <div class="level-left">
               <h1 class="title">Redacción À Punt / {{ title }}</h1>
                <nav class="breadcrumbs">
                    <slot name="breadcrumbs"/>
                </nav>
            </div>
            <div class="level-right username">
                <i class="fal fa-user"/>
                <slot name="username"></slot>
                <form :action="logoutUrl" method="POST" class="logout-form">
                    <button type="submit" class="fal fa-power-off"/>
                </form>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        name: "AppTopBar",
        props: {
            title: String,
            logoutUrl: String
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/theme';
    @import '../../../sass/variables';

    .top-bar {
        margin-bottom: 15px;
        padding: 15px 1.8em;
        padding-right: 0;
        height: 74px;
        background: rgb(133,186,210);
    background: linear-gradient(132deg, #305E9E 0%, #305E9E 85%);
        color: white;
        font-size: 18px;

        .level-right {
            background: rgba(255,255,255,0.1);
            padding: 0.5em;
            padding-right: 53px;
            position: relative;
            right: 40px;
            padding-left: 26px;
        }

        h1.title {
            font-size: 1.3rem;
            display: inline-block;
            margin-bottom: 0;
            color: white;
            margin-left: 4px;
        }

        .breadcrumbs {
            padding-left: 10px;
            margin-left: 10px;
            border-left: solid 1px #444;

            &:empty {
                display: none;
            }

            .breadcrumb-item {
                font-weight: bold;
                margin-right: 1em;
            }
        }

        .username {
            i {
                margin-right: 0.5em;
            }
        }

        .logout-form {
            position: absolute;
            margin-left: 25px;
            top: 0;
            right: 0;
            bottom: 0;

            button {
                color: #72BECC;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 12px;
                padding-right: 12px;
                border: none;
                outline: none;
                font-size: 22px;
                cursor: pointer;
                font-family: $font-awesome;
                background: none;
            }
        }
    }
</style>
