export default class CoverValue {
    constructor(element) {
        this.element = element
        this.items = []
    }
    set value(newValue) {
        if (Array.isArray(newValue)) {
            this.element.value = newValue[0]
        } else {
            this.element.value = newValue
        }
    }
    get value() {
        if (this.element.type === 'watch_more') {
            return [this.element.value];
        }
        return this.element.value
    }
    get data() {
        if (this.element.type === 'item') {
            return this.element;
        }
        return this.element.value || null
    }
    out() {
        var e = this.element

        // const id = this.id()
        // switch (e.element_type) {
        //     case 'watch_more':
        //         if (e.element_data[id][0] !== undefined) {
        //             e.element_data[id] = e.element_data[id][0];
        //         }
        //         break
        //     case 'item':
        //         e.element_data = {itemId: e.element_data[id]}
        //         break
        // }
        return {
            type: e.type,
            value: `${e.value}`
        }
    }
}
