var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          on: {
            click: function($event) {
              return _vm.openEdit(null)
            }
          }
        },
        [_vm._v("Nueva Imagen")]
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: { list: _vm.data, disabled: _vm.moving },
          on: { end: _vm.reorder }
        },
        _vm._l(_vm.data, function(element) {
          return _c("div", { key: element.id, staticClass: "card item" }, [
            _c("div", { staticClass: "card-image" }, [
              _c("figure", { staticClass: "image" }, [
                _c("img", {
                  staticClass: "carousel-image",
                  attrs: { src: element.image }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-content" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "media-content" }, [
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v("#" + _vm._s(element.position))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "title is-4" }, [
                    _vm._v(_vm._s(element.name))
                  ]),
                  _vm._v(" "),
                  _c("footer", { staticClass: "level" }, [
                    _c(
                      "div",
                      { staticClass: "level-left" },
                      [
                        _c("b-switch", {
                          attrs: {
                            name: "status",
                            "true-value": "1",
                            "false-value": "0"
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateStatus(element.id, $event)
                            }
                          },
                          model: {
                            value: element.status,
                            callback: function($$v) {
                              _vm.$set(element, "status", $$v)
                            },
                            expression: "element.status"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "level-right" }, [
                      _c(
                        "div",
                        { staticClass: "level-item" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "is-danger",
                              attrs: { icon: "cancel" },
                              on: {
                                click: function($event) {
                                  return _vm.destroy(element.id)
                                }
                              }
                            },
                            [_vm._v("Eliminar")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" \n                                "),
                      _c(
                        "div",
                        { staticClass: "level-item" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { icon: "pencil" },
                              on: {
                                click: function($event) {
                                  return _vm.openEdit(element.id)
                                }
                              }
                            },
                            [_vm._v("Editar")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalForm",
          attrs: {
            active: _vm.isEditing,
            width: "1100",
            "has-modal-card": "",
            "can-cancel": true
          },
          on: {
            "update:active": function($event) {
              _vm.isEditing = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "container form-wrapper" },
            [
              _c("modal-form", {
                attrs: { "carousel-id": _vm.carousel_id },
                model: {
                  value: _vm.id,
                  callback: function($$v) {
                    _vm.id = $$v
                  },
                  expression: "id"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }