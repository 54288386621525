var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    _c(
      "form",
      { attrs: { method: "POST", action: _vm.action } },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              type: _vm.errors.type("email"),
              message: _vm.errors.get("email")
            }
          },
          [
            _c("b-input", {
              attrs: {
                type: "email",
                name: "email",
                value: "",
                required: "",
                placeholder: "email",
                autocomplete: "email",
                autofocus: ""
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              type: _vm.errors.type("password"),
              message: _vm.errors.get("password")
            }
          },
          [
            _c("b-input", {
              attrs: {
                id: "password",
                type: "password",
                name: "password",
                required: "",
                autocomplete: "current-password"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { grouped: "", position: "" } },
          [
            _c(
              "b-button",
              {
                attrs: {
                  type: "is-fullwidth is-primary",
                  "native-type": "submit"
                }
              },
              [_vm._v("\n                Entrar\n            ")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }