var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video_id_selector" },
    [
      _c(
        "b-field",
        [
          _c("b-input", {
            attrs: {
              id: _vm.id,
              type: "text",
              name: _vm.id,
              value: _vm.videoId
            },
            on: {
              input: function($event) {
                return _vm.updateValue({ id: $event })
              }
            }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "control" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      _vm.isSelecting = true
                    }
                  }
                },
                [_vm._v("Seleccionar...")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isSelecting,
            "full-screen": "",
            "has-modal-card": "",
            "can-cancel": false
          },
          on: {
            "update:active": function($event) {
              _vm.isSelecting = $event
            }
          }
        },
        [
          _c("modal-form", {
            attrs: { value: _vm.itemSelected, "data-url": _vm.dataUrl },
            on: {
              input: function($event) {
                return _vm.updateValue($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }