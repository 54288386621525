<template>
    <div>
        <treeselect
            :flat="flat"
            :multiple="multiple"
            :open-on-click="true"
            :open-on-focus="true"
            :clearable="false"
            :value="selectedValues"
            v-on:input="updateValue($event)"
            :options="options">
            <div slot="value-label" slot-scope="{ node }">({{ node.id }}) {{ node.label }}</div>
            <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                ({{ node.id }}) {{ node.label }}
                <span v-if="shouldShowCount" :class="countClassName">{{ count }}</span>
            </label>
        </treeselect>
    </div>
</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {Local} from "../../lib/Request";

    export default {
        name: "MultiSelect",
        components: {
            Treeselect
        },
        props: {
            dataUrl: { required: true, default: null },
            value: { type: [Number, Array], required: false, default: null },
            multiple: { type: Boolean, default: true },
            flat: { type: Boolean, default: true },
        },
        data() {
            return {
                selectedValues: [],
                options: []
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                Local.get(this.dataUrl, {
                    params: {}
                })
                .then((result) => {
                    this.options = result.data;
                    this.updateValue(this.value)
                });
            },
            updateValue(value) {
                this.selectedValues = value
                this.$emit('input', value)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../sass/theme';

</style>
