<template>
    <div>
        <div class="listing-header">
            <search-input
                placeholder="Buscar por label"
                :value="labelStr"
                icon="tag"
                @input="search(searchStr, $event)"></search-input>
            <search-input
                :value="searchStr"
                @input="search($event, labelStr)"></search-input>
        </div>
        <b-table
            :data="data"
            :loading="loading"
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            backend-sorting
            :default-sort-direction="defaultSortDirection"
            :default-sort="[sortField, sortDir]"
            @sort="onSort"
        >
            <template slot-scope="props">
                <b-table-column field="id" label="ID" sortable>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column field="title" label="Titulo" sortable>
                    {{ props.row.title }}
                </b-table-column>
                <b-table-column field="bc_id" label="Brightcove ID" sortable>
                    {{ props.row.bc_id }}
                </b-table-column>
                <b-table-column field="publishing_date" label="Publicado el" sortable>
                    {{ props.row.publishing_date | moment(dateFormat) }}
                </b-table-column>
                <b-table-column field="insertion_date" label="Agregado el" sortable>
                    {{ props.row.insertion_date | moment(dateFormat) }}
                </b-table-column>
                <b-table-column field="update_date" label="Actualizado el" sortable>
                    {{ props.row.update_date | moment(dateFormat) }}
                </b-table-column>
                <b-table-column label="Actions">
                    <crud-actions :id="props.row.id" resource="media" :title="props.row.title" @deleted="fetchData"/>
                </b-table-column>

            </template>
        </b-table>
    </div>
</template>

<script>
    import {Local} from '../../lib/Request';
    import SearchInput from "../fields/SearchInput";

    export default {
        name: "MediaListing",
        components: {SearchInput},
        data: () => {
            return {
                data: [],
                total: 0,
                loading: false,
                sortField: 'insertion_date',
                sortDir: 'desc',
                defaultSortDirection: 'desc',
                page: 0,
                perPage: 10,
                dateFormat: process.env.MIX_DBT_DATE_FORMAT,
                searchStr: null,
                labelStr: null
            }
        },
        created() {
            this.fetchData()
        },
        computed: {
            searchQuery() {
                return this.searchStr !== null && this.searchStr.length >= 3 ? this.searchStr : '';
            },
            labelQuery() {
                return this.labelStr !== null && this.labelStr.length >= 3 ? this.labelStr : '';
            }
        },
        methods: {
            async fetchData() {
                try {
                    const result = await Local.get('/media/listing', {
                        params: {
                            'page': this.page,
                            'sortField': this.sortField,
                            'sortDir': this.sortDir,
                            'searchQuery': this.searchQuery,
                            'labelQuery': this.labelQuery,
                            ...this.query
                        }
                    });
                    // console.log(result.data);
                    this.data = result.data.data;
                    this.total = result.data.total;
                } catch (error) {
                    // todo add toast notification
                    console.error(error);
                }
            },
            onPageChange(page) {
                this.page = page;
                this.fetchData()
            },
            onSort(field, direction) {
                this.sortField = field;
                this.sortDir = direction;
                this.fetchData()
            },
            search(query, labelQry) {
                this.searchStr = query
                this.labelStr = labelQry

                this.fetchData()
            }
        }
    }

</script>

<style lang="scss">

</style>
