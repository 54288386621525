<template>
    <div class="thumb-box item-selector">
        <article v-if="itemSelected">
            <div>
                <img :src="previewImageUrl" class="preview" v-if="previewImageUrl"/>
            </div>
            <footer v-if="itemSelected && itemSelected.title" class="tags">
                {{itemSelected.title}}
            </footer>
        </article>
        <footer>
            <b-button class="button is-primary" @click="isSelecting = true">
                <span>Seleccionar...</span>
            </b-button>
            <b-button class="button is-danger" @click="updateValue({})" v-if="canRemove">
                <span>Borrar</span>
            </b-button>
        </footer>
        <b-modal :active.sync="isSelecting"
                 full-screen
                 has-modal-card :can-cancel="true">
            <modal-form :value="itemSelected" @input="updateValue($event)" :data-url="dataUrl"></modal-form>
        </b-modal>
    </div>
</template>

<script>
    import {Local} from "../../lib/Request";

    const ModalForm = {
        name: "ModalForm",
        props: {
            dataUrl: {required: true, default: null},
            value: {type: Object, default: null}
        },
        template: `
            <div class="modal-card item-selector-modal">
                <header class="modal-card-head is-clearfix">
                    <p class="modal-card-title">Seleccionar Items</p>
                </header>
                <section class="modal-card content">
                    <news-listing
                        :data-url="dataUrl"
                        v-model="value"
                        :cbSelected="onSelected"
                        :show-type="true"
                        :selector="true"></news-listing>
                </section>
                <footer class="modal-card-foot">

                </footer>
            </div>
        `,
        methods: {
            onSelected(value) {
                this.$emit('input', value)
                console.log('onSelected', value, this.value);
                this.$parent.close()
            }
        }
    }

    export default {
        name: "ItemSelector",
        components: {
            ModalForm
        },
        props: {
            dataUrl: {required: true, default: null},
            value: {required: false, default: null},
            defaultValue: {type: Object, default: null},
            canRemove: { type: Boolean, default: false }
        },
        data() {
            return {
                itemSelected: {},
                options: [],
                isSelecting: false,
            }
        },
        mounted() {
            if (this.defaultValue) {
                this.itemSelected = this.defaultValue
            }
        },
        computed: {
            previewImageUrl() {
                if (this.itemSelected.thumbnail_url !== undefined) {
                    return this.itemSelected.thumbnail_url
                }
                return 'https://statics.apuntmedia.es/APUNTDEFAULT.jpg'
            }
        },
        methods: {
            updateValue(value) {
                this.itemSelected = value
                this.$emit('input', value.id)
            }
        }
    }
</script>

<style lang="scss">
    @import '../../../sass/theme';

    .modal {
        .modal-card.item-selector-modal {
            width: 100%;

            .modal-card.content {
                width: 100%;
                padding: 1em;
                background: #fff;
            }
        }
    }
</style>
