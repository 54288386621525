<template>
    <div class="modal-card reorder-modal">
        <header class="modal-card-head is-clearfix">
            <p class="modal-card-title">Reorganizar Items</p>
        </header>
        <header class="modal-card-head toolbar is-clearfix">
            <b-field grouped>
                <b-field horizontal label="Ordenar">
                    <b-select v-model="mode" @change.native="doReorder($event.target.value, 'mode')">
                        <option value="0">Por defecto (manual)</option>
                        <option value="1">Por Fecha (auto)</option>
                        <option value="2">Por Titulo (auto)</option>
                    </b-select>
                </b-field>
                <b-field class="gap has-text-centered">|</b-field>
                <b-field>
                    <button class="button is-success" type="button" @click="doReorder(null, 'update')">Guardar orden
                        manual
                    </button>
                </b-field>
                <b-field>
                    <button class="button is-danger" type="button" @click="doReorder(null, 'remove')">Remover orden
                    </button>
                </b-field>
            </b-field>
            <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
        </header>
        <section class="modal-card-body content">
            <b-message>
                - Para activar el reorden manual, elije "Ordenar: Por defecto"<br>
                - Recuerda presionar "Guardar orden manual" despues de reordenar los items</b-message>
            <draggable :list="treeData" @end="doReorder()" :disabled="disabled">
                <div class="item level box"
                     v-for="(item, index) in items"
                     :key="index"
                     :item="item">
                    <div class="level-left">
                        <div class="level-item">
                            <p class="title is-5" style="color: #999;">#{{ index+1 }}</p>
                        </div>
                        <div class="level-item item">
                            <p class="title is-6">({{ item.id }}) {{ item.title }}</p>
                        </div>
                        <span class="level-item has-text-centered">
                            <b-tag type="is-info">{{ item.type }}</b-tag>
                    </span>
                    </div>
                    <span class="level-right">
                        <span class="level-item icon handle is-6" v-if="!disabled">
                            <i class="fa fa-align-justify"></i>
                        </span>
                </span>
                </div>
            </draggable>
        </section>
        <footer class="modal-card-foot">
            <button class="button pull-right" type="button" @click="$parent.close()">Cerrar</button>
        </footer>
    </div>
</template>
<script>
    import draggable from 'vuedraggable'
    import {Local} from "../../lib/Request";

    export default {
        name: "OrderItemsModal",
        components: {
            draggable
        },
        props: {
            dataUrl: {required: true, default: null},
            id: {type: Number, required: true}
        },
        data() {
            return {
                treeData: [],
                items: [],
                mode: 0,
                isLoading: false
            };
        },
        mounted() {
            this.fetchData();
        },
        computed: {
            disabled() {
                return this.mode > 0
            }
        },
        methods: {
            fetchData() {
                Local.get(`/labels/reorder/listing/${this.id}`)
                    .then((result) => {
                        console.log(result.data.label)
                        this.mode = result.data.label.auto_order;
                        this.items = result.data.items;
                        this.treeData = result.data.items;
                    });
            },
            async doReorder(mode, action) {
                console.log(this.mode)
                this.isLoading = true;
                try {
                    let formData = new FormData()

                    switch (action) {
                        case 'update':
                            const ids = this.treeData.map((child) => {
                                return child.id
                            })
                            formData.append('ids', JSON.stringify(ids))
                            break
                        case 'mode':
                            formData.append('mode', mode)
                            break
                        case 'remove':
                            break
                        default:
                            this.isLoading = false;
                            return
                    }

                    let result = await Local.post(`/labels/reorder/${action}/${this.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    if (!(result.data && result.data.id)) {
                        throw new Error('Backend error');
                    }

                    console.log(result.data);

                    this.$buefy.toast.open({
                        message: 'El orden ha sido guardado',
                        type: 'is-success',
                        container: '.reorder-modal'
                    });

                    this.items = result.data.items;
                    this.treeData = result.data.items;

                    this.isLoading = false
                } catch (e) {
                    console.log(e);
                    this.isLoading = false
                }
            }
        }
    }
</script>
<style lang="scss">
    .modal {
        .modal-card.reorder-modal {
            .modal-card-title {
                margin-bottom: 0;
            }

            .toolbar {
                justify-content: space-between;

                .field {
                    margin: auto;

                    .label {
                        display: inline-block;
                        min-width: 150px;
                        padding-right: 1em;
                    }

                    &.gap {
                        width: 150px;
                        color: #d2d2d2;
                        font-size: 2em;
                        margin-top: -0.3em;
                    }
                }

                .button {
                    display: inline-block;
                    margin-left: 1em;
                    margin-right: 1em;
                }
            }

            .item {
                font-size: 1.5em;
                cursor: move;
            }
        }
    }
</style>
