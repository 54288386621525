var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form covers-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box tv-container is-clearfix" },
          _vm._l(_vm.form.elements, function(e, index) {
            return _c(
              "div",
              { staticClass: "tv-item has-text-centered is-pulled-left" },
              [
                _c(
                  "b-field",
                  [
                    _c("item-selector", {
                      attrs: {
                        "default-value": e.data,
                        "can-remove": true,
                        "data-url": "/news/listing/video,audio"
                      },
                      model: {
                        value: e.value,
                        callback: function($$v) {
                          _vm.$set(e, "value", $$v)
                        },
                        expression: "e.value"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/news" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }