export default class Errors {
    constructor() {
        this.messages = new Map();
    }

    type(id) {
        if (this.has(id)) {
            return 'is-danger';
        }
        return null
    }

    has(id) {
        return this.messages.has(id)
    }

    get(id) {
        return this.messages.get(id)
    }

    set(id) {
        return this.messages.set(id)
    }

    delete(id) {
        this.messages.delete(id)
    }

    reset() {
        this.messages.clear()
    }

    setAll(messages) {
        if (messages === undefined) { return }
        let out = Object
            .entries(messages)
            .map((value, key) => {
                console.log(value)
                return [value[0], value[1]];
            });
        this.messages = new Map(out);
    }
}
