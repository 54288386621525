var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form media-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Thumbnail",
              type: "is-danger",
              type: _vm.errors.type("thumbnail"),
              message: _vm.errors.get("thumbnail")
            }
          },
          [
            _c("image-upload-box", {
              ref: "thumbnail",
              attrs: { "default-url": _vm.form.thumbnail_url },
              model: {
                value: _vm.thumbData,
                callback: function($$v) {
                  _vm.thumbData = $$v
                },
                expression: "thumbData"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            Tamano maximo de archivo 3Mb. Resolucion maxima recomendada 1280x720 con una relacion de aspecto 16:9\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Tipo de Asset",
              type: _vm.errors.type("type"),
              message: _vm.errors.get("type")
            }
          },
          [
            _c(
              "b-select",
              {
                attrs: { name: "type", required: "" },
                model: {
                  value: _vm.form.type,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type"
                }
              },
              [
                _c("option", { attrs: { value: "video" } }, [_vm._v("Video")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "audio" } }, [_vm._v("Audio")])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "* Titulo",
              type: _vm.errors.type("title"),
              message: _vm.errors.get("title")
            }
          },
          [
            _c("b-input", {
              attrs: {
                name: "title",
                type: "text",
                expanded: "",
                required: ""
              },
              on: {
                change: function($event) {
                  return _vm.bindCleanTitle(_vm.form.title)
                },
                input: function($event) {
                  return _vm.bindCleanTitle(_vm.form.title)
                }
              },
              model: {
                value: _vm.form.title,
                callback: function($$v) {
                  _vm.$set(_vm.form, "title", $$v)
                },
                expression: "form.title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Url",
              type: _vm.errors.type("clean_title"),
              message: _vm.errors.get("clean_title")
            }
          },
          [
            _c("slug-input", {
              ref: "slug",
              attrs: {
                prefix: "https://apuntmedia.es/../",
                name: "clean_title",
                "synced-on-start": _vm.syncTitleWithSlug,
                expanded: "",
                required: true
              },
              model: {
                value: _vm.form.clean_title,
                callback: function($$v) {
                  _vm.$set(_vm.form, "clean_title", $$v)
                },
                expression: "form.clean_title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Descripcion",
              type: _vm.errors.type("description"),
              message: _vm.errors.get("description")
            }
          },
          [
            _c("summernote", {
              staticClass: "form-control",
              attrs: { name: "editor" },
              model: {
                value: _vm.form.description,
                callback: function($$v) {
                  _vm.$set(_vm.form, "description", $$v)
                },
                expression: "form.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "* Idioma",
              type: _vm.errors.type("language"),
              message: _vm.errors.get("language")
            }
          },
          [
            _c(
              "b-select",
              {
                attrs: { name: "language", required: "" },
                model: {
                  value: _vm.form.language,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "language", $$v)
                  },
                  expression: "form.language"
                }
              },
              [
                _c("option", { attrs: { value: "ES" } }, [_vm._v("Espanol")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "CA" } }, [
                  _vm._v("Valenciano")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "EN" } }, [_vm._v("Ingles")])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Calificacion moral",
              type: _vm.errors.type("rating"),
              message: _vm.errors.get("rating")
            }
          },
          [
            _c(
              "b-select",
              {
                attrs: { name: "rating", required: "" },
                model: {
                  value: _vm.form.rating,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rating", $$v)
                  },
                  expression: "form.rating"
                }
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v("Publico General")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("+7")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "10" } }, [_vm._v("+10")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "12" } }, [_vm._v("+12")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "13" } }, [_vm._v("+13")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "16" } }, [_vm._v("+16")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "18" } }, [_vm._v("+18")])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Fecha de publicacion",
              type: _vm.errors.type("publishing_date"),
              message: _vm.errors.get("publishing_date")
            }
          },
          [
            _c("calendar-input", {
              model: {
                value: _vm.form.publishing_date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "publishing_date", $$v)
                },
                expression: "form.publishing_date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Fecha de finalizacion",
              type: _vm.errors.type("expiration_date"),
              message: _vm.errors.get("expiration_date")
            }
          },
          [
            _c("calendar-input", {
              model: {
                value: _vm.form.expiration_date,
                callback: function($$v) {
                  _vm.$set(_vm.form, "expiration_date", $$v)
                },
                expression: "form.expiration_date"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Keywords",
              type: _vm.errors.type("keywords"),
              message: _vm.errors.get("keywords")
            }
          },
          [
            _c("b-taginput", {
              attrs: {
                ellipsis: "",
                icon: "label",
                placeholder: "Agregar keyword"
              },
              model: {
                value: _vm.form.keywords,
                callback: function($$v) {
                  _vm.$set(_vm.form, "keywords", $$v)
                },
                expression: "form.keywords"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("b-message", [
          _vm._v(
            "\n            Lista de palabras clave separadas por comas\n        "
          )
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Labels",
              type: _vm.errors.type("labels"),
              message: _vm.errors.get("labels")
            }
          },
          [
            _c("multi-select", {
              attrs: { multiple: true, "data-url": "/labels/listing" },
              model: {
                value: _vm.form.labels,
                callback: function($$v) {
                  _vm.$set(_vm.form, "labels", $$v)
                },
                expression: "form.labels"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Player ID",
              type: _vm.errors.type("player_bc"),
              message: _vm.errors.get("player_bc")
            }
          },
          [
            _c("async-select", {
              attrs: { multiple: true, "data-url": "/bcapi/players-list" },
              model: {
                value: _vm.form.player_bc,
                callback: function($$v) {
                  _vm.$set(_vm.form, "player_bc", $$v)
                },
                expression: "form.player_bc"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Ad Keys",
              type: _vm.errors.type("ad_keys"),
              message: _vm.errors.get("ad_keys")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "ad_keys", type: "text", expanded: "" },
              model: {
                value: _vm.form.ad_keys,
                callback: function($$v) {
                  _vm.$set(_vm.form, "ad_keys", $$v)
                },
                expression: "form.ad_keys"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level" }, [
          _c(
            "div",
            { staticClass: "level-item has-text-centered" },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Video 360",
                    type: _vm.errors.type("v360"),
                    message: _vm.errors.get("v360")
                  }
                },
                [
                  _c("b-switch", {
                    attrs: {
                      name: "v360",
                      "true-value": "1",
                      "false-value": "0"
                    },
                    model: {
                      value: _vm.form.v360,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "v360", $$v)
                      },
                      expression: "form.v360"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "level-item has-text-centered" },
            [
              _c(
                "b-field",
                { attrs: { label: "Requiere registro" } },
                [
                  _c("b-switch", {
                    attrs: {
                      name: "require_user_sign_in",
                      "true-value": "1",
                      "false-value": "0"
                    },
                    model: {
                      value: _vm.form.require_user_sign_in,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "require_user_sign_in", $$v)
                      },
                      expression: "form.require_user_sign_in"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c("div", { staticClass: "level-item" }, [
                _vm._v(
                  "\n                        * Campos Obligatorios\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c("b-button", { attrs: { tag: "a", href: "/media" } }, [
                    _vm._v("Cancelar\n                        ")
                  ])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v("Guardar\n                        ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }