var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "slide-menu" }, [_c("main-nav")], 1),
    _vm._v(" "),
    _c("input", {
      staticClass: "nav-trigger",
      attrs: { type: "checkbox", id: "nav-trigger" }
    }),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "page-content" },
      [
        _vm._t("contentheader"),
        _vm._v(" "),
        _c(
          "article",
          { staticClass: "content" },
          [
            _c("img", {
              staticClass: "welcome--logo",
              attrs: { src: "/img/logo-der.svg", alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "welcome--img",
              attrs: { src: "/img/bg-bienvenido.png", alt: "" }
            }),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        ),
        _vm._v(" "),
        _vm._m(0)
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "text--footer" }, [
        _vm._v("XCMS by crossmedia")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "logo--footer" }, [
        _c("img", {
          staticClass: "footer--img1",
          attrs: { src: "/img/logo_apunt_footer.svg", alt: "" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }