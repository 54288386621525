var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-item" }, [
    _c(
      "a",
      {
        staticClass: "item-label",
        attrs: { href: _vm.url, onclick: _vm.onClick }
      },
      [
        _c("span", { staticClass: "icon" }, [
          _c("i", { class: _vm.iconClass })
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "menu-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("span", { staticClass: "accessory-title" }, [
          _vm._v(_vm._s(_vm.accessoryTitle || _vm.title))
        ])
      ]
    ),
    _vm._v(" "),
    _vm.accessoryUrl
      ? _c(
          "a",
          { staticClass: "item-accessory", attrs: { href: _vm.accessoryUrl } },
          [
            _c("span", { staticClass: "icon" }, [
              _c("i", { class: _vm.accessoryClass })
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }