var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.nosubmit($event)
          }
        }
      },
      [
        _vm._t("csrf"),
        _vm._v(" "),
        _c("div", { staticClass: "columns" }),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Titulo", message: _vm.errors.get("name") } },
          [
            _c("b-input", {
              attrs: { name: "name", type: "text", required: "" },
              model: {
                value: _vm.form.name,
                callback: function($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Titulo (linea 2)",
              message: _vm.errors.get("name_line_two")
            }
          },
          [
            _c("b-input", {
              attrs: { name: "name_line_two", type: "text" },
              model: {
                value: _vm.form.name_line_two,
                callback: function($$v) {
                  _vm.$set(_vm.form, "name_line_two", $$v)
                },
                expression: "form.name_line_two"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Descripción", message: _vm.errors.get("text") } },
          [
            _c("b-input", {
              attrs: { name: "text", type: "text" },
              model: {
                value: _vm.form.text,
                callback: function($$v) {
                  _vm.$set(_vm.form, "text", $$v)
                },
                expression: "form.text"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Link", message: _vm.errors.get("link") } },
          [
            _c("b-input", {
              attrs: { name: "link", type: "text" },
              model: {
                value: _vm.form.link,
                callback: function($$v) {
                  _vm.$set(_vm.form, "link", $$v)
                },
                expression: "form.link"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Image",
              type: "is-danger",
              message: _vm.errors.get("image")
            }
          },
          [
            _c("image-upload-box", {
              ref: "image",
              attrs: { "default-url": _vm.form.image },
              model: {
                value: _vm.imageData,
                callback: function($$v) {
                  _vm.imageData = $$v
                },
                expression: "imageData"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Color titulo 1",
              message: _vm.errors.get("color_titulo_1")
            }
          },
          [
            _c("color-input", {
              ref: "color1",
              attrs: { name: "color_titulo_1" },
              model: {
                value: _vm.form.color_titulo_1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "color_titulo_1", $$v)
                },
                expression: "form.color_titulo_1"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Color titulo 2",
              message: _vm.errors.get("color_titulo_2")
            }
          },
          [
            _c("color-input", {
              ref: "color2",
              attrs: { name: "color_titulo_2" },
              model: {
                value: _vm.form.color_titulo_2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "color_titulo_2", $$v)
                },
                expression: "form.color_titulo_2"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Color texto",
              message: _vm.errors.get("color_texto")
            }
          },
          [
            _c("color-input", {
              ref: "color3",
              attrs: { name: "color_texto" },
              model: {
                value: _vm.form.color_texto,
                callback: function($$v) {
                  _vm.$set(_vm.form, "color_texto", $$v)
                },
                expression: "form.color_texto"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Color bg movil",
              message: _vm.errors.get("color_bg_movil")
            }
          },
          [
            _c("color-input", {
              ref: "color4",
              attrs: { name: "color_bg_movil" },
              model: {
                value: _vm.form.color_bg_movil,
                callback: function($$v) {
                  _vm.$set(_vm.form, "color_bg_movil", $$v)
                },
                expression: "form.color_bg_movil"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("footer", { staticClass: "form-controls" }, [
          _c("nav", { staticClass: "level" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "p",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "is-success",
                      attrs: {
                        "native-type": "button",
                        loading: _vm.submitting
                      },
                      on: { click: _vm.submitForm }
                    },
                    [
                      _vm._v(
                        "\n                            Guardar\n                        "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _vm._v(
          "\n                        * Campos Obligatorios\n                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }