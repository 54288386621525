<template>
    <div class="form media-form">
        <form @submit.prevent="nosubmit">
            <slot name="csrf"/>

            <b-field label="Posición"
                     :type="errors.type('position')"
                     :message="errors.get('position')">
                <b-select v-model="form.position" name="position" required>
                    <option value="preroll">Preroll</option>
                    <option value="midroll">Midroll</option>
                    <option value="postroll">Postroll</option>
                </b-select>
            </b-field>

            <b-field label="* Titulo"
                     :type="errors.type('title')"
                     :message="errors.get('title')">
                <b-input
                    name="title"
                    type="text"
                    v-model="form.title"
                    expanded
                />
            </b-field>

            <b-field
                label="Descripcion"
                :type="errors.type('description')"
                :message="errors.get('description')">
                <b-input
                    name="description"
                    type="text"
                    v-model="form.description"
                    expanded
                />
            </b-field>

            <b-field
                label="Skip Time (mm:ss)"
                :type="errors.type('skip_time')"
                :message="errors.get('skip_time')">
                <time-input
                    name="skip_time"
                    v-model="form.skip_time"/>
            </b-field>

            <b-field label="Publicidad / Bc Id"
                     :type="errors.type('bc_id')"
                     :message="errors.get('bc_id')">
                <async-select
                    :multiple="true"
                    :value="form.bc_id"
                    @input="fetchDuration"
                    data-url="/bcapi/ads-list"
                />
            </b-field>

            <b-field
                label="Duración (mm:ss)"
                v-if="form.bc_id"
                :type="errors.type('duration')"
                :message="errors.get('duration')">
                <time-input
                    name="duration"
                    :editable="false"
                    v-model="form.duration"/>
            </b-field>

            <b-field label="Url"
                     :type="errors.type('url')"
                     :message="errors.get('url')">
                <b-input
                    name="url"
                    type="text"
                    v-model="form.url"
                    expanded required
                />
            </b-field>

            <b-field label="Fecha de publicacion"
                     :type="errors.type('start_at')"
                     :message="errors.get('start_at')">
                <calendar-input
                    v-model="form.start_at">
                </calendar-input>
            </b-field>

            <b-field label="Fecha de finalizacion"
                     :type="errors.type('end_at')"
                     :message="errors.get('end_at')">
                <calendar-input
                    v-model="form.end_at"></calendar-input>
            </b-field>

            <hr>

            <b-tabs type="is-boxed">
                <b-tab-item>
                    <template slot="header">
                        <b-icon icon="source-pull"></b-icon>
                        <span> Especiales <b-tag rounded> {{ form.specials.length }} </b-tag> </span>
                    </template>
                    <specials-listing
                        data-url="/specials/listing"
                        :values="form.specials"
                        :cbSelected="(values) => { this.form.specials = values }"
                        :show-type="true"
                        :selector="true"
                        :multiple="true"
                        :query="{hasBcId: 1, showAll: 1}"></specials-listing>
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <b-icon icon="information-outline"></b-icon>
                        <span> Media Assets <b-tag rounded> {{ form.assets.length }} </b-tag> </span>
                    </template>
                    <news-listing
                        data-url="/news/listing/audio,video"
                        :values="form.assets"
                        :cbSelected="(values) => { this.form.assets = values }"
                        :show-type="true"
                        :selector="true"
                        :multiple="true"
                        :query="{hasBcId: 1}"></news-listing>
                </b-tab-item>
<!--                <b-tab-item>-->
<!--                    <template slot="header">-->
<!--                        <b-icon icon="information-outline"></b-icon>-->
<!--                        <span> Labels <b-tag rounded> {{ form.labels.length }} </b-tag> </span>-->
<!--                    </template>-->
<!--                    <b-field>-->
<!--                        <multi-select-->
<!--                            :multiple="true"-->
<!--                            v-model="form.labels"-->
<!--                            data-url="/labels/listing"-->
<!--                        />-->
<!--                    </b-field>-->
<!--                </b-tab-item>-->
            </b-tabs>

            <footer class="form-controls">
                <nav class="level">
                    <!-- Left side -->
                    <div class="level-left">
                        <div class="level-item">
                            * Campos Obligatorios
                        </div>
                        <div class="level-item">
                            <b-button tag="a" href="/media">Cancelar
                            </b-button>
                        </div>
                    </div>

                    <!-- Right side -->
                    <div class="level-right">
                        <p class="level-item">
                            <b-button
                                native-type="button"
                                @click="submitForm"
                                :loading="submitting">Guardar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </footer>
        </form>

    </div>
</template>

<script>
    import moment from 'moment';
    import Errors from "../../lib/Errors";
    import {Local} from '../../lib/Request';
    import Summernote from '../../lib/Summernote';
    import ImageUploadBox from "../fields/ImageUploadBox";
    import SlugInput from "../fields/SlugInput";
    import MultiSelect from "../fields/MultiSelect";
    import AsyncSelect from "../fields/AsyncSelect";
    import CalendarInput from "../fields/CalendarInput";
    import TimeInput from "../fields/TimeInput";

    export default {
        name: "MediaForm",
        components: {
            TimeInput, CalendarInput, AsyncSelect, MultiSelect, ImageUploadBox, SlugInput, Summernote
        },
        props: {
            csrf: {required: false, default: null},
            action: {required: false, default: null},
            errorMessages: {required: false, default: null},
            values: {required: false, default: null}
        },
        data() {
            return {
                errors: new Errors(),
                submitting: false,
                thumbData: null,
                id: null,
                dateFormat: process.env.MIX_DBT_DATE_FORMAT,
                duration: null,
                form: {
                    position: 'preroll',
                    title: null,
                    description: null,
                    skip_time: 0,
                    duration: null,
                    url: null,
                    bc_id: null,
                    labels: [],
                    specials: [],
                    assets: [],
                    start_at: new Date(),
                    end_at: moment().add(1, 'years').toDate()
                }
            }
        },
        created() {
            this.errors.setAll(this.errorMessages);

            if (this.values && this.values.id) {
                this.id = this.values.id;
                this.form = {...this.form, ...this.values};
                this.duration = this.form.duration
                this.form.start_at = new Date(this.form.start_at)
                this.form.end_at = new Date(this.form.end_at)
            }
        },
        methods: {
            async submitForm() {
                try {
                    this.submitting = true;

                    let formData = new FormData()

                    formData.append('position', this.form.position || '');
                    formData.append('title', this.form.title || '');
                    formData.append('description', this.form.description || '');
                    formData.append('skip_time', this.form.skip_time)
                    formData.append('duration', this.duration)
                    formData.append('url', this.form.url);
                    formData.append('bc_id', this.form.bc_id);
                    formData.append('start_at', moment(this.form.start_at).format());
                    formData.append('end_at', moment(this.form.end_at).format());

                    formData.append('labels', this.form.labels || []);
                    formData.append('specials', this.form.specials || []);
                    formData.append('assets', this.form.assets || []);

                    console.log(this.form);

                    let url = `/ads/update`
                    if (this.id) {
                        url = `${url}/${this.id}`
                        formData.append('id', this.id);
                    }

                    let result = await Local.post(url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    this.errors.reset();

                    this.$buefy.toast.open({
                        message: 'La publicidad ha sido guardado',
                        type: 'is-success'
                    });

                    const values = result.data.values
                    if (!this.form.id && result.data.id) {
                        setTimeout(() => {
                            let toks = window.location.href.split('/');
                            toks.push(result.data.id);
                            window.location.href = toks.join('/');
                        }, 2000);
                    } else if (values && values.thumbnail_url) {
                        this.$refs.thumbnail.reset(values.thumbnail_url);
                    }
                    this.submitting = false;
                } catch (error) {
                    console.log(error)
                    this.submitting = false;

                    if (error.response.data && error.response.data.errors) {
                        this.errors.setAll(error.response.data.errors);
                    }
                    this.$buefy.toast.open({
                        duration: 5000,
                        closable: true,
                        message: 'Hay problemas con el formulario',
                        type: 'is-danger'
                    });
                }
            },
            fetchDuration(bc_id) {
                if (bc_id === null) {
                    return
                }
                const dataUrl = `/bcapi/ads-media/${bc_id}`
                Local.get(dataUrl, {
                    params: {}
                })
                    .then((result) => {
                        const data = result.data.data
                        this.form.media_url = data.src
                        this.form.duration = data.duration
                        this.duration = data.duration
                        this.form.bc_id = bc_id
                    });
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";

</style>
