var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "main-navigation" },
    [
      _c("menu-item", {
        attrs: {
          url: "/specials",
          title: "Especiales",
          "accessory-url": "/specials/edit",
          "accessory-title": "Nuevo Especial",
          "icon-class": "fal fa-sparkles"
        }
      }),
      _vm._v(" "),
      _c("menu-item", {
        attrs: {
          url: "/shorturls",
          title: "Acortador de URL",
          "accessory-url": "/acortador-urls/edit",
          "accessory-title": "Nuevo URL",
          "icon-class": "fal fa-link"
        }
      }),
      _vm._v(" "),
      _c("menu-item", {
        attrs: {
          url: "/ads",
          title: "Publicidad",
          "icon-class": "fal fa-megaphone",
          "accessory-url": "/ads/edit",
          "accessory-title": "Nueva Publicidad"
        }
      }),
      _vm._v(" "),
      _c("menu-item", {
        attrs: {
          url: "/users",
          title: "Usuarios",
          "icon-class": "fal fa-user-friends",
          "accessory-url": "/users/edit",
          "accessory-title": "Nuevo Usuario"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img--menu",
        attrs: { src: "/img/logo-crossmedia.svg", alt: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }